import React from 'react'

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Map() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <iframe 
            title="Mapa de hblue"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14561.872621526003!2d-110.316891!3d24.155309!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb494d6dacd3ef115!2sHotel+HBlue!5e0!3m2!1ses!2smx!4v1561747781009!5m2!1ses!2smx" 
            style={{
                border: 0,
                width: "100%",
                height: matches?200:400
            }}
            allowFullScreen
        />
    )
}

export default Map
import React from 'react'
// Estilos
import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery    from '@material-ui/core/useMediaQuery'
// Material
import Typography       from '@material-ui/core/Typography'
import Paper            from '@material-ui/core/Paper'
import Button           from '@material-ui/core/Button'
import MobileStepper    from '@material-ui/core/MobileStepper'
import IconButton       from '@material-ui/core/IconButton'
import Badge            from '@material-ui/core/Badge'
import CircularProgress from '@material-ui/core/CircularProgress'
// Iconos
import KeyboardArrowLeft    from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight   from '@material-ui/icons/KeyboardArrowRight'
import PhotoIcon            from '@material-ui/icons/Photo'
import CloudOff             from '@material-ui/icons/CloudOffTwoTone'
// Complementos
import SwipeableViews       from 'react-swipeable-views'
import { useTranslation }   from 'react-i18next'
import Axios    from 'axios'
// componentes
import Dialog   from './Dialog'

const useStyles = makeStyles(theme => ({
    cardRoot: {
        flexGrow: 1
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        display: 'block',
        width: '100%',
        height: 'auto'
    },
    cardContent: {
        paddingTop: 16,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    title:{
        fontWeight: "600"
    },
    action: {
        position: 'absolute',
        top: 56,
        margin: 8,
        color: "#fff",
        backgroundColor: "#00000064"
    },
    mensaje: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

function Rooms({ language }) {

    const theme     = useTheme()
    const matches   = useMediaQuery(theme.breakpoints.down('sm'))
    const classes   = useStyles()
    const { t }     = useTranslation()

    const [step, setStep]       = React.useState(0)
    const [height, setHeight]   = React.useState(false)
    const [images, setImages]   = React.useState(null)
    const [rooms, setRooms]     = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError]     = React.useState(false)

    React.useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        Axios.get('https://hotelws.adftec.com/api/get_room_types')
        .then(response => {
            setRooms(response.data)
            setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            setLoading(false)
            setError(true)
        })
    }

    if(loading) {
        return (
            <div className={classes.mensaje}>
                <CircularProgress size={64} style={{marginBottom: 4}} />
                <Typography variant="h6">
                    {t('Cuartos.MensajeCarga')}
                </Typography>
                <Typography variant="subtitle1">
                    {t('Cuartos.DescripcionCarga')}
                </Typography>
            </div>
        )
    }   
    if(error) {
        return (
            <div className={classes.mensaje}>
                <CloudOff style={{fontSize: 64, marginBottom: 4}} />
                <Typography variant="h6">
                    {t('Cuartos.Error')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {t('Cuartos.DescripcionError')}
                </Typography>
                <Button variant="contained" onClick={() => getData()}>
                    {t('Cuartos.Intentar')}
                </Button>
            </div>
        )
    }
    return (
        <React.Fragment>
            <div className={classes.cardRoot}>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={step}
                    onChangeIndex={(step) => {
                        setStep(step)
                        setHeight(true)
                    }}
                    enableMouseEvents
                    animateHeight={height}
                >
                    {rooms.map((room, index) => (
                        <div key={index}>
                            <Paper square elevation={0} className={classes.cardHeader}>
                                <Typography variant={matches?"subtitle1":"h6"} className={classes.title}>
                                    {room.nombre_hab[language]}
                                </Typography>
                            </Paper>
                            <img className={classes.img} src={room.image_url} alt={room.nombre[language]} />
                            {room.imagenes.length > 0 && 
                            <IconButton className={classes.action} onClick={() => setImages(room.imagenes)}>
                                <Badge badgeContent={room.imagenes.length} color="secondary">
                                    <PhotoIcon />
                                </Badge>
                            </IconButton>}
                            <Paper square elevation={0} className={classes.cardContent}>
                                <Typography variant={matches?"body1":"subtitle1"}>
                                    {room.descripcion_hab[language]}
                                </Typography>
                            </Paper>
                        </div>
                    ))}
                </SwipeableViews>
                <MobileStepper
                    steps={rooms.length}
                    position="static"
                    variant="text"
                    activeStep={step}
                    nextButton={
                        <Button size="small" onClick={() => {
                            setStep(step + 1)
                            setHeight(true)
                        }} disabled={step === rooms.length - 1}>
                            {t('Action.Next')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={() => {
                            setStep(step - 1)
                            setHeight(true)
                        }} disabled={step === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('Action.Back')}
                        </Button>
                    }
                />
            </div>
            {images && <Dialog open={true} handleClose={() => setImages(null)} imagenes={images} />}
        </React.Fragment>
    )
}

export default Rooms
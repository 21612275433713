import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

function Section1() {

    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root} style={{
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('+ require('../../images/114533011.0cc0adc0.jpg') + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: matches?200:350
        }}>
            <Typography variant={matches?"h6":"h3" }style={{color: "#fff"}} gutterBottom align="center">
                {t('Section3.Title1')}
            </Typography>
            <Button variant="contained" color="primary">
                {t('Section3.Button1')}
            </Button>
        </div>
    )
}

export default Section1
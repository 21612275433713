import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CloudOff from '@material-ui/icons/CloudOffTwoTone'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    content:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        padding: 16
    }
}))

export default function ErrorDisponibilidad({ back, retry }) {

    const classes = useStyles()
    const { t } = useTranslation() 

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <CloudOff style={{fontSize: 64, marginBottom: 4}} />
                <Typography variant="h6" gutterBottom>
                    {t('Nueva.ErrorCuartos')}
                </Typography>
            </CardContent>
            <div className={classes.actions}>
                <Button style={{marginRight: 8}} onClick={() => back()}>
                    {t('Nueva.ErrorCuartosAtras')}
                </Button>
                <Button variant="contained" color="primary" onClick={() => retry()}>
                    {t('Nueva.ErrorCuartosReintentar')}
                </Button>
            </div>
        </Card>
    )
}
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Gallery from 'react-grid-gallery'

const useStyles = makeStyles(theme => ({
    spacing: {
        height: 16
    },
    gallery: {
        padding: 8,
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto",
    }
}))

function Habitaciones() {

    const classes = useStyles()
    const HABITACIONES = [
        {
            src: require("../../images/gallery/hblue_hab01.1d13e01d.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab01.1d13e01d.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab02.de49e7db.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab02.de49e7db.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab03.e5191009.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab03.e5191009.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab04.e2902866.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab04.e2902866.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hab05.10e1d3c0.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab05.10e1d3c0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab06.a5917384.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab06.a5917384.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab07.24b34f6d.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab07.24b34f6d.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab08.fd604274.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab08.fd604274.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab09.61097f79.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab09.61097f79.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab10.3971b9ed.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab10.3971b9ed.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab11.e4e15e74.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab11.e4e15e74.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab12.a5e164d3.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab12.a5e164d3.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab13.7e54fc90.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab13.7e54fc90.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab14.3a9ce8f0.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab14.3a9ce8f0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab15.e16545a5.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab15.e16545a5.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab16.c34d8f93.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab16.c34d8f93.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab17.3cbe50ea.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab17.3cbe50ea.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab18.91f1cc29.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab18.91f1cc29.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab19.d7087b2b.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab19.d7087b2b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab20.8a98737b.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab20.8a98737b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab21.d4510228.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab21.d4510228.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab22.bf1dbd70.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab22.bf1dbd70.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab23.3530dd5f.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab23.3530dd5f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab24.182cf99b.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab24.182cf99b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab25.ec0aa57d.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab25.ec0aa57d.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab26.01daafbc.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab26.01daafbc.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hab27.a5cc8b29.jpg"),
            thumbnail: require("../../images/gallery/hblue_hab27.a5cc8b29.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
    ]

    return (
        <div className={classes.gallery}>
            <Gallery 
                images={HABITACIONES}
                enableImageSelection={false}
                onClickImage={()=>{}}
                backdropClosesModal={true}
                rowHeight={320}
            />
        </div>
    )
}

export default Habitaciones
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Gallery from 'react-grid-gallery'

const useStyles = makeStyles(theme => ({
    spacing: {
        height: 16
    },
    gallery: {
        padding: 8,
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto",
    }
}))

function Amenidades() {

    const classes = useStyles()
    const AMENIDADES = [
        {
            src: require("../../images/gallery/hblue_am01.652de160.jpg"),
            thumbnail: require("../../images/gallery/hblue_am01.652de160.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am02.739eef19.jpg"),
            thumbnail: require("../../images/gallery/hblue_am02.739eef19.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am03.3c5eb246.jpg"),
            thumbnail: require("../../images/gallery/hblue_am03.3c5eb246.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am04.e0694876.jpg"),
            thumbnail: require("../../images/gallery/hblue_am04.e0694876.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am05.62dd85fe.jpg"),
            thumbnail: require("../../images/gallery/hblue_am05.62dd85fe.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am06.cf28b409.jpg"),
            thumbnail: require("../../images/gallery/hblue_am06.cf28b409.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am07.5a636876.jpg"),
            thumbnail: require("../../images/gallery/hblue_am07.5a636876.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am08.be2422db.jpg"),
            thumbnail: require("../../images/gallery/hblue_am08.be2422db.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am09.52dc488f.jpg"),
            thumbnail: require("../../images/gallery/hblue_am09.52dc488f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am10.4646c0dd.jpg"),
            thumbnail: require("../../images/gallery/hblue_am10.4646c0dd.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am11.e19b3a6b.jpg"),
            thumbnail: require("../../images/gallery/hblue_am11.e19b3a6b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am13.6ba515a7.jpg"),
            thumbnail: require("../../images/gallery/hblue_am13.6ba515a7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am14.420f6ece.jpg"),
            thumbnail: require("../../images/gallery/hblue_am14.420f6ece.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am15.657673b0.jpg"),
            thumbnail: require("../../images/gallery/hblue_am15.657673b0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am16.5ced8690.jpg"),
            thumbnail: require("../../images/gallery/hblue_am16.5ced8690.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am17.f8a6197f.jpg"),
            thumbnail: require("../../images/gallery/hblue_am17.f8a6197f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel19.08b000a7.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel19.08b000a7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am18.10578459.jpg"),
            thumbnail: require("../../images/gallery/hblue_am18.10578459.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am19.230baa54.jpg"),
            thumbnail: require("../../images/gallery/hblue_am19.230baa54.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am20.fe0ef8dc.jpg"),
            thumbnail: require("../../images/gallery/hblue_am20.fe0ef8dc.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am21.b31833b8.jpg"),
            thumbnail: require("../../images/gallery/hblue_am21.b31833b8.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am22.abc0bf29.jpg"),
            thumbnail: require("../../images/gallery/hblue_am22.abc0bf29.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am23.05c29e71.jpg"),
            thumbnail: require("../../images/gallery/hblue_am23.05c29e71.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am25.6da33ac7.jpg"),
            thumbnail: require("../../images/gallery/hblue_am25.6da33ac7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am26.f0f1971a.jpg"),
            thumbnail: require("../../images/gallery/hblue_am26.f0f1971a.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am27.834a87dd.jpg"),
            thumbnail: require("../../images/gallery/hblue_am27.834a87dd.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_am30.27e04aa8.jpg"),
            thumbnail: require("../../images/gallery/hblue_am30.27e04aa8.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
    ]

    return (
        <div className={classes.gallery}>
            <Gallery 
                images={AMENIDADES}
                enableImageSelection={false}
                onClickImage={()=>{}}
                backdropClosesModal={true}
                rowHeight={320}
            />
        </div>
    )
}

export default Amenidades
import React from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Container, Typography, Grid, Paper } from '@material-ui/core'
import Amenities from './Amenities'
import Rooms from './Rooms'

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 32
    },
    title:{
        fontWeight: "600"
    },
    list: {
        padding: 32,
        backgroundColor: "#e0e0e0"
    },
    rooms:{
        padding: 16
    },
}))

function Content() {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const { t, i18n } = useTranslation()
    const classes = useStyles()
    
    return (
        <Container fixed className={classes.root}>
            <Paper>
                <Grid container wrap="wrap-reverse">
                    <Grid item xs={12} md={4} className={classes.list}>
                        <Typography variant={matches?"subtitle1":"h6"} className={classes.title} align="center">
                            {t('Cuartos.Amenidades')}
                        </Typography>
                        <Amenities t={t} />
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.rooms}>
                        <Rooms language={i18n.language} />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default Content
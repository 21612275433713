import React from 'react'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'

function ExpiryTextField(props) {

  const { inputRef, onChange, ...other } = props;
  const { i18n } = useTranslation()

    return (
        <NumberFormat 
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="##/##" 
            placeholder={i18n.language === 'es'?"MM/AA":"MM/YY"} 
            mask={i18n.language === 'es'?['M', 'M', 'A', 'A']:['M', 'M', 'Y', 'Y']}
        />
    );
}

export default ExpiryTextField
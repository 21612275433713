import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Welcome from '../screens/Welcome'
import LaPaz from '../screens/LaPaz'
import Politicas from '../screens/Politicas'
import Privacidad from '../screens/Privacidad'
import Galeria from '../screens/Galeria'
import Cuartos from '../screens/Cuartos'
import NuevaReservacion from '../screens/NuevaReservacion'
import Contacto from '../screens/Contacto'
import Consultar from '../screens/Consultar'

function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/"} component={Welcome} />
                <Route exact path={"/lapaz"} component={LaPaz} />
                <Route exact path={"/policies"} component={Politicas} />
                <Route exact path={"/privacy"} component={Privacidad} />
                <Route exact path={"/gallery"} component={Galeria} />
                <Route exact path={"/rooms"} component={Cuartos} />
                <Route exact path={"/new"} component={NuevaReservacion} />
                <Route exact path={"/contact"} component={Contacto} />
                <Route exact path={"/search"} component={Consultar} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router
import React                from 'react'
import Button               from '@material-ui/core/Button'
import Card                 from '@material-ui/core/Card'
import CardContent          from '@material-ui/core/CardContent'
import CardActions          from '@material-ui/core/CardActions'
import Typography           from '@material-ui/core/Typography'
import Table                from '@material-ui/core/Table'
import TableBody            from '@material-ui/core/TableBody'
import TableRow             from '@material-ui/core/TableRow'
import TableCell            from '@material-ui/core/TableCell'
import TableHead            from '@material-ui/core/TableHead'
import TextField            from '@material-ui/core/TextField'
import MenuItem             from '@material-ui/core/MenuItem'
import { makeStyles }       from '@material-ui/styles'
import { useTranslation }   from 'react-i18next'
import ErrorOutlineIcon     from '@material-ui/icons/ErrorOutline'
import PeopleIcon           from '@material-ui/icons/People'
import ErrorIcon            from '@material-ui/icons/Error'
import RoomCard             from './RoomCard'
import Galeria              from './Galeria'


const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px'
    },
    nodisponible: {
        display: 'flex',
        alignItems: 'center'
    },
    imagen: {
        width: 100,
        [theme.breakpoints.up("sm")]: {
            width: 200
        }
    },
    tablaText: {
        fontSize: "0.875rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.25rem",
        }
    },
    error: {
        borderColor: theme.palette.error.main,
        borderStyle: "solid",
        borderWidth: 1,
        padding: "8px 32px",
        display: 'flex',
        alignItems: 'center'
    }
}))

function Paso2({ next, back, disponibles, defaultY }) {

    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [selected, setSelected] = React.useState([])
    const [imagenes, setImagenes] = React.useState(null)
    const [reservados, setReservados] = React.useState([])
    const [error, setError] = React.useState(false)

    const handleNext = () => {
        let cuartos_reservados = []
        selected.forEach(id => {
            cuartos_reservados.push({
                cuarto: disponibles.find(cuarto => cuarto.id === id),
                huespedes: 1
            })
        })
        if(cuartos_reservados.length > 0) {
            setReservados(cuartos_reservados)
            setError(false)
            defaultY()
        } else {
            setError(true)
        }
    }

    const handleHuesped = (value, id) => {
        setReservados(reservados.map((item, index) => {
            if(index === id) {
                return {...item,
                    huespedes: value
                }
            } 
            return item
        }))
    }

    function list(count) {
        let list = []
        for (let i = 1; i <= count; i++) {
           list.push(<MenuItem key={i} value={i}>{i}</MenuItem>) 
        }
        return list;
    }
    if(disponibles.length < 1) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.nodisponible}>
                        <ErrorOutlineIcon style={{fontSize: 64, marginRight: 8}} />
                        <div>
                            <Typography variant="subtitle1" style={{fontWeight: 700}}>
                                {t('Nueva.Disponibilidad')}
                            </Typography>
                            <Typography variant="body1">
                                {t('Nueva.Disponibilidad2')}
                            </Typography>
                        </div>
                    </CardContent>
                    <CardActions style={{justifyContent: 'center', paddingBottom: 16}}>
                        <Button variant="contained" color="primary" onClick={() => back()}>
                            {t('Nueva.DisponibilidadButton')}
                        </Button>
                    </CardActions>
                </Card>
                <div style={{height: 16}} />
            </div>
        )
    }
    if(reservados.length > 0) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {t('Nueva.Step2Title2')}
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        {t('Nueva.TableTitle1')}
                                    </TableCell>
                                    <TableCell>
                                        {t('Nueva.TableTitle2')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {reservados.map((res, index) => (
                                <TableRow key={index}>
                                    <TableCell padding="checkbox">
                                        <img src={res.cuarto.image_url} alt="" className={classes.imagen} />
                                    </TableCell>
                                    <TableCell className={classes.tablaText}>
                                        {res.cuarto.nombre_hab[i18n.language]}
                                    </TableCell>
                                    <TableCell>
                                    <TextField
                                        select
                                        value={res.huespedes}
                                        onChange={({target}) => {
                                            handleHuesped(target.value, index)
                                        }}
                                        variant="outlined"
                                        InputProps={{startAdornment: <PeopleIcon style={{marginRight: 4}} />}}
                                    >
                                        {list(res.cuarto.huespedes)}
                                    </TextField>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                    <CardActions style={{justifyContent: 'flex-end', padding: "16px 8px"}}>
                        <Button color="default" onClick={() => {
                            setSelected([])
                            setReservados([])
                            defaultY()
                        }} variant="contained">
                            {t('Action.Back')}
                        </Button>
                        <Button color="primary" onClick={() => next(reservados)} variant="contained">
                            {t('Action.Next')}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {t('Nueva.Step2Title1')}
                    </Typography>
                    {disponibles.map( (cuarto, index) => 
                    <RoomCard 
                        key={cuarto.id} 
                        cuarto={cuarto} 
                        indice={index}
                        onChange={(cantidad, cuarto) => {
                            let nuevas = []
                            nuevas = selected.filter(item => item !== cuarto.id)
                            for(let i = 0; i < cantidad; i++) {
                                nuevas.push(cuarto.id)
                            }
                            setSelected(nuevas)
                        }}
                        openGallery={(imagenes) => setImagenes(imagenes)}
                    />)}
                    {error && 
                    <div className={classes.error}>
                        <ErrorIcon style={{fontSize: 64}} color="error" />
                        <div>
                            <Typography variant="h6" color="error">
                                Para continuar seleccione la cantidad de habitaciones que desea reservar.
                            </Typography>
                        </div>
                    </div>}
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end', padding: "16px 8px"}}>
                    <Button color="default" onClick={() => back()} variant="contained">
                        {t('Action.Back')}
                    </Button>
                    <Button color="primary" onClick={() => handleNext()} variant="contained">
                        {t('Action.Next')}
                    </Button>
                </CardActions>
            </Card>
            <div style={{height: 16}} />
            {imagenes && <Galeria imagenes={imagenes} handleClose={() => setImagenes(null)} />}
        </div>
    )
}

export default Paso2
import React from 'react'
import { Card, Typography, CardContent, Grid, TextField, CardActions, Button } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import NumberTextField from '../../components/NumberTextField'
import ExpiryTextField from '../../components/ExpiryTextField'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    input: {
        padding: 8
    },
    title: {
        padding: "16px 16px 0px 16px"
    },
    divider: {
        marginLeft: 16,
        marginRight: 16,
        width: 'calc(100% - 32px)'
    },
    spacing: {
        height: 32
    }
}))

function Paso4({ next, back }) {

    let submit = React.useRef()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()
    const { t } = useTranslation()

    const [name, setName] = React.useState("")
    const [number, setNumber] = React.useState("")
    const [expiry, setExpiry] = React.useState("")
    const [cvv, setCvv] = React.useState("")
    const [type, setType] = React.useState("")
    const [maxLength, setMaxLength] = React.useState(16)
    const [isValid, setIsValid] = React.useState(false)
    const [focused, setFocused] = React.useState("number")

    const handleSubmit = (e) => {
        e.preventDefault()
        const tarjeta = {
            nombre: name,
            numero: number,
            mes: expiry.slice(0,2),
            anio: expiry.slice(2,4),
            cvv: cvv,
            tipo: type,
            valido: isValid
        }
        next(tarjeta)
    }

    return (
        <div>
            <Card className={classes.root}>
                <Typography variant={matches?"h6":"h4"} className={classes.title} align="center">
                    {t('Nueva.Step4')}
                </Typography>
                <CardContent>
                    <Cards 
                        name={name}
                        number={number}
                        expiry={expiry}
                        cvc={cvv}
                        acceptedCards={['visa', 'mastercard', 'amex', 'discover', 'dinersclub']}
                        callback={(type, isValid) => {
                            setType(type.issuer)
                            setMaxLength(type.maxLength)
                            setIsValid(isValid)
                        }}
                        placeholders={{
                            name: t('Nueva.CardName')
                        }}
                        locale={{ valid: t('Nueva.CardExpiry') }}
                        focused={focused}
                    />
                    <div className={classes.spacing} />
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <NumberTextField 
                                    label={t('Nueva.InputCardNumber')}
                                    value={number}
                                    onChange={(value) => setNumber(value)}
                                    maxLength={maxLength}
                                    onFocus={() => setFocused("number")}
                                    id="input-numero"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField
                                    fullWidth
                                    label={t('Nueva.InputCardName')}
                                    variant="outlined"
                                    value={name}
                                    onChange={({target}) => setName(target.value.toUpperCase())}
                                    inputProps={{
                                        maxLength: 21
                                    }}
                                    onFocus={() => setFocused("name")}
                                    id="input-titular"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputCardExpiry')}
                                    variant="outlined"
                                    value={expiry}
                                    onChange={({target}) => setExpiry(target.value)}
                                    InputProps={{
                                        type: "text",
                                        inputComponent: ExpiryTextField
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onFocus={() => setFocused("expiry")}
                                    id="input-expiracion"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.input}>
                                <NumberTextField 
                                    label={t('Nueva.InputCardSecurity')}
                                    value={cvv}
                                    onChange={(value) => setCvv(value)}
                                    maxLength={4}
                                    onFocus={() => setFocused("cvc")}
                                    id="input-cvc"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <button hidden type="submit" name="submit" ref={submit} />
                    </form>
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end', padding: "16px 8px"}}>
                    <Button onClick={back} name="atras" variant="contained">
                        {t('Action.Back')}
                    </Button>
                    <Button color="primary" onClick={() =>  submit.current.click()} name="siguiente" variant="contained">
                        {t('Action.Next')}
                    </Button>
                </CardActions>
            </Card>
            <div style={{height: 16}} />
        </div>
    )
}

export default Paso4
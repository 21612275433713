import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Gallery from 'react-grid-gallery'

const useStyles = makeStyles(theme => ({
    spacing: {
        height: 16
    },
    gallery: {
        padding: 8,
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto",
    }
}))

function Alberca() {

    const classes = useStyles()
    const ALBERCA = [
        {
            src: require("../../images/gallery/hblue_alb01.b7365dba.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb01.b7365dba.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb02.fa4abe6b.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb02.fa4abe6b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb03.2f075942.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb03.2f075942.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb04.bae1ade1.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb04.bae1ade1.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb05.13582ad4.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb05.13582ad4.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb06.52ad6ad7.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb06.52ad6ad7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb07.b1bbb815.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb07.b1bbb815.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb08.ae047111.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb08.ae047111.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_alb09.40891fe0.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb09.40891fe0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb10.c6d3fbdd.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb10.c6d3fbdd.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb11.e19b3a6b.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb11.e19b3a6b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb13.cd80ea0f.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb13.cd80ea0f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb14.d5e53686.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb14.d5e53686.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb15.bed097bc.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb15.bed097bc.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb16.87307224.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb16.87307224.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb17.4a8d16f0.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb17.4a8d16f0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb18.7d5bda94.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb18.7d5bda94.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb19.08b000a7.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb19.08b000a7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb20.f0f1971a.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb20.f0f1971a.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb21.834a87dd.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb21.834a87dd.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb22.f5135df2.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb22.f5135df2.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb23.454d7111.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb23.454d7111.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb24.0bf91d1b.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb24.0bf91d1b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb25.b0ac6442.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb25.b0ac6442.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_alb27.56814abc.jpg"),
            thumbnail: require("../../images/gallery/hblue_alb27.56814abc.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
    ]

    return (
        <div className={classes.gallery}>
            <Gallery 
                images={ALBERCA}
                enableImageSelection={false}
                onClickImage={()=>{}}
                backdropClosesModal={true}
                rowHeight={320}
            />
        </div>
    )
}

export default Alberca
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { Dialog, DialogTitle, Paper, Typography, MobileStepper, Button, DialogActions, DialogContent } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 'auto',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
}))

function TourDialog({ 
    open,
    handleClose
}) {

    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme();
    const [step, setStep] = React.useState(0)
    const titleStep = [
        {
            label: t('ToursDialog.Step1'),
            image: require('../../images/Espiritu_Santo_Island.JPG')
        },
        {
            label: t('ToursDialog.Step2'),
            image: require('../../images/Sunset_over_Harbor_-_La_Paz_-_Baja_California_Sur_-_Mexico.jpg')
        },
        {
            label: t('ToursDialog.Step3'),
            image: require('../../images/whale-shark-281497.jpg')
        },
        {
            label: t('ToursDialog.Step4'),
            image: require('../../images/40201730612_db02f02c2b_b.jpg')
        },
        {
            label: t('ToursDialog.Step5'),
            image: require('../../images/16827786452_6e7420309a_b.jpg')
        },
        {
            label: t('ToursDialog.Step6'),
            image: require('../../images/fishing-577514_960_720.jpg')
        },
        {
            label: t('ToursDialog.Step7'),
            image: require('../../images/aquaimages.jpg')
        }
    ]

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="hours-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="hours-dialog-title">{t('ToursDialog.Title')}</DialogTitle>
            <DialogContent>
                <div className={classes.root}>
                    <Paper square elevation={0} className={classes.header}>
                        <Typography variant="h6">{titleStep[step].label}</Typography>
                    </Paper>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={step}
                        onChangeIndex={(step) => setStep(step)}
                        enableMouseEvents
                    >
                        {titleStep.map(item => (
                            <div key={item.label}>
                                <img
                                    className={classes.img}
                                    src={item.image}
                                    alt={item.label}
                                />
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={titleStep.length}
                        position="static"
                        variant="text"
                        activeStep={step}
                        nextButton={
                        <Button size="small" onClick={() => setStep(step + 1)} disabled={step === titleStep.length - 1}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={() => setStep(step - 1)} disabled={step === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                        }
                    />
                    <Typography variant="caption">
                        {t('ToursDialog.Alert')}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {t('ToursDialog.Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TourDialog
import React from 'react'
import { Stepper, Step, Grid, Hidden, StepButton, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'

import Resumen from './Resumen'
import Paso1 from './Paso1'
import Paso2 from './Paso2'
import Paso3 from './Paso3'
import Paso4 from './Paso4'
import Final from './Final'
import Loading from './Loading'
import ErrorDisponibilidad from './ErrorDisponibilidad'
import moment from 'moment'

function getSteps(t) {
    return [t('Nueva.Step1'), t('Nueva.Step2'), t('Nueva.Step3'), t('Nueva.Step4')]
}

function defaultY() {
    if(window.scrollY > 400) {
        window.scrollTo(0,272)
    }
}

function RootContainer() {

    const { t } = useTranslation()

    const urlParams = new URLSearchParams(window.location.search)
    const paso = urlParams.has('from') && urlParams.has('to') ? 1:0

    const [activeStep, setActiveStep]   = React.useState(paso)
    const [reservacion, setReservacion] = React.useState({
        desde: urlParams.get('from'),
        hasta: urlParams.get('to'),
        huesped: null
    })
    const [disponibles, setDisponibles] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)

    const getRooms = (desde, hasta) => {
        setLoading(true)
        setError(false)
        Axios.post('https://hotelws.adftec.com/api/get_availability', {
            hotel_id: 2,
            desde: desde,
            hasta: hasta
        })
        .then(response => {
            setDisponibles(response.data)
            setLoading(false)
        })
        .catch(error => {
            setError(true)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        if(paso) {
            getRooms(moment(reservacion.desde).format("YYYY-MM-DD"), moment(reservacion.hasta).format("YYYY-MM-DD"))
        }
    },[reservacion.desde, reservacion.hasta, paso])

    const steps = getSteps(t)

    const RenderSteps = () => {
        if(error) {
            return(
                <ErrorDisponibilidad back={() => setActiveStep(0)} retry={getRooms} />
            )
        }
        if(loading) {
            return (<Loading />)
        }
        switch(activeStep) {
            case 0: 
                return (
                    <Paso1 
                    from={reservacion.desde}
                    to={reservacion.hasta}
                    next={(desde, hasta) => {
                        setActiveStep(1)
                        setReservacion({
                            ...reservacion,
                            desde: desde.format("YYYY-MM-DD"),
                            hasta: hasta.format("YYYY-MM-DD")
                        })
                        getRooms(desde.format("YYYY-MM-DD"), hasta.format("YYYY-MM-DD"))
                        defaultY()
                    }} />
                )
            case 1:
                return(
                    <Paso2 
                    next={(cuartos_reservados) => {
                        setActiveStep(2)
                        setReservacion({...reservacion,
                            cuartos_reservados: cuartos_reservados
                        })
                        defaultY()
                    }} 
                    back={() => {
                        setActiveStep(0)
                        defaultY()
                    }}
                    defaultY={defaultY}
                    disponibles={disponibles} />
                )
            case 2:
                return(
                    <Paso3 
                    next={(huesped) => {
                        setActiveStep(3)
                        setReservacion({
                            ...reservacion,
                            huesped
                        })
                        defaultY()
                    }} 
                    back={() => {
                        setActiveStep(1)
                        defaultY()
                    }}
                    huesped={reservacion.huesped} />
                )
            case 3:
                return(
                    <Paso4 
                    next={(tarjeta) => {
                        setActiveStep(4)
                        setReservacion({
                            ...reservacion,
                            tarjeta
                        })
                        defaultY()
                    }}
                    back={() => {
                        setActiveStep(2)
                        defaultY()
                    }} />
                )
            default:
                return null
        }
    }

    return (
        <React.Fragment>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton onClick={() => setActiveStep(index)}>{label}</StepButton>
                    </Step>
                ))}
            </Stepper>
            <div style={{height: 16}} />
            <Container fixed>
                {activeStep < 4 ? <Grid container justify="center">
                    <Grid item xs={12} md={activeStep > 1 ? 7 : 12}>
                        <RenderSteps />
                    </Grid>
                    <Hidden smDown>
                        {activeStep > 1 && <Grid item md={5}>
                            <Resumen reservacion={reservacion} /> 
                        </Grid>}
                    </Hidden>
                </Grid>:
                <Final reservacion={reservacion} onBack={() => setActiveStep(3)} />}
            </Container>
            <div style={{height: 32}} />
        </React.Fragment>
    )
}

export default RootContainer
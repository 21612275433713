import React from 'react'

import Dialog           from '@material-ui/core/Dialog'
import DialogTitle      from '@material-ui/core/DialogTitle'
import DialogContent    from '@material-ui/core/DialogContent'
import DialogActions    from '@material-ui/core/DialogActions'
import Button           from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

export default function Terminos({ open, handleClose }) {

    const { t } = useTranslation()

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            scroll="paper"
        >
            <DialogTitle>Terminos y condiciones</DialogTitle>
            <DialogContent dividers>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Action.Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
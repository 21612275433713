import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, TextField, InputAdornment, Button } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#000",
    },
    menu: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "flex-end",
        paddingTop: 16,
        paddingBottom: 16,
    },
    link: {
        textDecoration: "none",
        padding: "4px 8px",
        color: "#FFF",
        '&:hover': {
            color: "#bdbdbd"
        }
    },
    input: {
        marginRight: 16,
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 8
    },
    action: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    }
}))

function Footer() {

    const classes = useStyles()
    const { t } = useTranslation()
    const [email, setEmail] = React.useState()

    return (
        <footer>
            <div className={classes.root}>
                <Container fixed className={classes.menu}>
                    <Link to="/search" className={classes.link}>
                        <Typography variant="body1" color="inherit">
                            {t('Footer.Booking')}
                        </Typography>
                    </Link>
                    <Link to="/policies" className={classes.link}>
                        <Typography variant="body1" color="inherit">
                            {t('Footer.Policies')}
                        </Typography>
                    </Link>
                    <Link to="/privacy" className={classes.link}>
                        <Typography variant="body1" color="inherit">
                            {t('Footer.Privacy')}
                        </Typography>
                    </Link>
                    <Link to="/" className={classes.link}>
                        <Typography variant="body1" color="inherit">
                            {t('Footer.FAQ')}
                        </Typography>
                    </Link>
                </Container>
                <div style={{
                    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.0)), url('+require('../images/alberca.jpg')+')',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    textAlign: 'center',
                    paddingTop: 32,
                    paddingBottom: 32
                }}>
                    <Typography variant="h5" style={{color: "#fff"}} gutterBottom>
                        {t('Footer.News')}
                    </Typography>
                    <div className={classes.action}>
                        <TextField 
                            value={email}
                            onChange={({target}) => setEmail(target.value)}
                            placeholder= {t('Footer.EmailInput')}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.input}
                            id="input-news"
                        />
                        <Button color="primary" variant="contained">
                            {t('Footer.NewsInput')}
                        </Button>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
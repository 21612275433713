import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button, DialogActions } from '@material-ui/core';
import AccessTimeIcno from '@material-ui/icons/AccessTime'

const useStyles = makeStyles(theme => ({
    primary: {
        fontWeight: "600"
    }
}))

function HoursDialog({ 
    open,
    handleClose
}) {

    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="hours-dialog-title"
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="hours-dialog-title">{t('HoursDialog.Title')}</DialogTitle>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{backgroundColor: "#2196f3"}}>
                            <AccessTimeIcno />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={t('HoursDialog.Subtitle1')} 
                        secondary={t('HoursDialog.Text1')} 
                        primaryTypographyProps={{className: classes.primary}}
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{backgroundColor: "#ff9800"}}>
                            <AccessTimeIcno />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={t('HoursDialog.Subtitle2')} 
                        secondary={t('HoursDialog.Text2')}
                        primaryTypographyProps={{className: classes.primary}}
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{backgroundColor: "#607d8b"}}>
                            <AccessTimeIcno />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={t('HoursDialog.Subtitle3')} 
                        secondary={t('HoursDialog.Text3')}
                        primaryTypographyProps={{className: classes.primary}}
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{backgroundColor: "#009688"}}>
                            <AccessTimeIcno />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={t('HoursDialog.Subtitle4')} 
                        secondary={t('HoursDialog.Text4')} 
                        primaryTypographyProps={{className: classes.primary}}
                    />
                </ListItem>
            </List>
            <DialogActions style={{justifyContent: 'flex-end'}}>
                <Button onClick={handleClose}>
                    {t('HoursDialog.Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default HoursDialog
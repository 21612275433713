import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { Dialog, MobileStepper, Button } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 'auto',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
    paper: {
        backgroundColor: "#00000000",
        boxShadow: "none"
    }
}))

function TourDialog({ 
    imagenes,
    handleClose
}) {

    const classes = useStyles()
    const theme = useTheme()
    const [step, setStep] = React.useState(0)

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="hours-dialog-title"
            fullWidth
            maxWidth="sm"
            classes={{paper: classes.paper}}
        >
                <div className={classes.root}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={step}
                        onChangeIndex={(step) => setStep(step)}
                        enableMouseEvents
                    >
                        {imagenes.map( (item, index) => (
                            <div key={index}>
                                <img
                                    className={classes.img}
                                    src={item.image_url}
                                    alt=""
                                />
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={imagenes.length}
                        style={{
                            position: "absolute",
                            width: "100%",
                            top: 0,
                            backgroundColor: "#00000046",
                            color: "#fff"
                        }}
                        position="static"
                        variant="text"
                        activeStep={step}
                        nextButton={
                        <Button size="small" onClick={() => setStep(step + 1)} disabled={step === imagenes.length - 1} color="inherit">
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={() => setStep(step - 1)} disabled={step === 0} color="inherit">
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        </Button>
                        }
                    />
                </div>
        </Dialog>
    )
}

export default TourDialog
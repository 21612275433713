import React from 'react'

import { Container, Typography, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function Description() {

    const { t } = useTranslation()

    return (
        <Container fixed>
            <Paper style={{padding: 32, margin: '16px 8px'}}>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle1')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item1')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item2')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item3')}
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem3_1')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem3_2')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem3_3')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem3_4')}
                                </Typography>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item4')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item5')}
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem5_1')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem5_2')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem5_3')}
                                </Typography>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item6')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item7')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item8')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle2')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item9')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item10')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item11')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item12')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item13')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle3')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {t('Politicas.Item14')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item15')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item16')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item17')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item18')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item19')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item20')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item21')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle4')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item22')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item23')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item24')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle5')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item25')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item26')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item27')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item28')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item29')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item30')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item31')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle6')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item32')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item33')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" gutterBottom>
                    {t('Politicas.Subtitle7')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item34')}
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_1')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_2')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_3')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_4')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_5')}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {t('Politicas.SubItem34_6')}
                                </Typography>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            {t('Politicas.Item35')}
                        </Typography>
                    </li>
                </ul>
            </Paper>
        </Container>
    )
}

export default Description
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { Dialog, DialogTitle, MobileStepper, Button, DialogContent, DialogActions } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { useTranslation } from 'react-i18next'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 'auto',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
}))

function TourDialog({ 
    imagenes,
    open,
    handleClose
}) {

    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const [step, setStep] = React.useState(0)

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="hours-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="hours-dialog-title">{t('Cuartos.DialogTitle')}</DialogTitle>
            <DialogContent>
                <div className={classes.root}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={step}
                        onChangeIndex={(step) => setStep(step)}
                        enableMouseEvents
                    >
                        {imagenes.map( (item, index) => (
                            <div key={index}>
                                <img
                                    className={classes.img}
                                    src={item.image_url}
                                    alt=""
                                />
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={imagenes.length}
                        position="static"
                        variant="text"
                        activeStep={step}
                        nextButton={
                        <Button size="small" onClick={() => setStep(step + 1)} disabled={step === imagenes.length - 1}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={() => setStep(step - 1)} disabled={step === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                        }
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {t('Action.Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TourDialog
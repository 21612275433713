import React from 'react'
import { Paper, Typography, Divider, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
 
const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px',
        padding: 16,
        backgroundColor: "#eeeeee"
    },
    spacing: {
        height: 16
    },
    blankCell: {
        borderWidth: 0
    },
    dividerCell: {
        borderBottom: "solid 1px rgba(0, 0, 0, 0.12)"
    }
}))

function Resumen({ reservacion }) {

    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const { huesped, cuartos_reservados } = reservacion

    return (
        <Paper className={classes.root}>
            <Typography variant="h6">
                {t('Resumen.Title')}
            </Typography>
            <Divider />
            <div className={classes.spacing} /> 
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.blankCell}>
                            {t('Resumen.CheckIn')}
                        </TableCell>
                        <TableCell className={classes.blankCell} align="right">
                            {moment(reservacion.desde).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.dividerCell}>
                            {t('Resumen.CheckOut')}
                        </TableCell>
                        <TableCell className={classes.dividerCell} align="right">
                            {moment(reservacion.desde).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")}
                        </TableCell>
                    </TableRow>
                    {cuartos_reservados && 
                    <React.Fragment>
                        <TableRow>
                            <TableCell className={classes.blankCell} colSpan={2} style={{fontWeight: 700, fontSize: "1rem"}}>
                                {t('Resumen.Habitaciones')}
                            </TableCell>
                        </TableRow>
                        {cuartos_reservados.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell className={index === cuartos_reservados.length -1 ? classes.dividerCell:classes.blankCell}>
                                    {item.cuarto.nombre_hab[i18n.language]}
                                </TableCell>
                                <TableCell className={index === cuartos_reservados.length -1 ? classes.dividerCell:classes.blankCell} align="right" padding="none" style={{justifyContent: "center"}}>
                                    <PeopleIcon /> {item.huespedes}
                                </TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>}
                    {huesped &&
                    <React.Fragment>
                        <TableRow>
                            <TableCell className={classes.blankCell} colSpan={2} style={{fontWeight: 700, fontSize: "1rem"}}>
                                {t('Resumen.Huesped')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.blankCell}>
                                {t('Resumen.Nombre')}
                            </TableCell>
                            <TableCell className={classes.blankCell} align="right">
                                {huesped.nombre + " " + huesped.apellido}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.blankCell}>
                                {t('Resumen.Email')}
                            </TableCell>
                            <TableCell className={classes.blankCell} align="right">
                                {huesped.email}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.blankCell}>
                                {t('Resumen.Telefono')}
                            </TableCell>
                            <TableCell className={classes.blankCell} align="right">
                                {huesped.telefono}
                            </TableCell>
                        </TableRow>
                    </React.Fragment>}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default Resumen
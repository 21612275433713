import React from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Container, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32
    },
    text: {
        fontWeight: "400"
    }
}))

function Description() {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation()
    const classes = useStyles()
    
    return (
        <Container fixed className={classes.root}>
            <Typography variant={matches?"body1":"h6"} align="justify" className={classes.text}>
                {t('Cuartos.Description1')}
            </Typography>
            <Typography variant={matches?"body1":"h6"} align="justify" className={classes.text}>
                {t('Cuartos.Description2')}
            </Typography>
        </Container>
    )
}

export default Description
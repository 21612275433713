import React from 'react'
import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'

function inputNumbrer(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
        />
    )
}

function NumberTextField({ autoFocus, label, value, onChange, maxLength, onFocus, required }) {
    return (
        <TextField 
            autoFocus={autoFocus}
            fullWidth
            label={label}
            variant="outlined"
            value={value}
            onChange={({target}) => onChange(target.value)}
            InputProps={{
                inputComponent: inputNumbrer,
                inputProps: {  maxLength: maxLength }
            }}
            onFocus={onFocus}
            required={required}
        />
    )
}

export default NumberTextField
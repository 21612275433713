import React from 'react'

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import LensIcon from '@material-ui/icons/Lens'

function Amenities({ t }) {

    const Lista = [
        t('Cuartos.Amenidad1'),
        t('Cuartos.Amenidad2'),
        t('Cuartos.Amenidad3'),
        t('Cuartos.Amenidad4'),
        t('Cuartos.Amenidad5'),
        t('Cuartos.Amenidad6'),
        t('Cuartos.Amenidad7'),
        t('Cuartos.Amenidad8'),
        t('Cuartos.Amenidad9'),
        t('Cuartos.Amenidad10'),
        t('Cuartos.Amenidad11'),
        t('Cuartos.Amenidad12'),
        t('Cuartos.Amenidad13'),
        t('Cuartos.Amenidad14'),
        t('Cuartos.Amenidad15'),
        t('Cuartos.Amenidad16'),
        t('Cuartos.Amenidad17'),
    ]

    return (
        <List>
            {Lista.map((item, index) => (
                <ListItem key={index} dense>
                    <ListItemIcon>
                        <LensIcon style={{color:"#4caf50"}} fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
    )
}

export default Amenities
import React from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Container, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32
    }
}))

function Description() {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <Container>
                <Typography variant={matches?"h6":"h4"} component="p" align="center" gutterBottom>
                    {t('LaPaz.Subtitle')}
                </Typography>
                <Typography variant={matches?"subtitle1":"h6"} component="p" align="center" style={{fontWeight: "400"}}>
                    {t('LaPaz.Description1')}
                </Typography>
                <Typography variant={matches?"subtitle1":"h6"} component="p" align="center" style={{fontWeight: "400"}}>
                    {t('LaPaz.Description2')}
                </Typography>
            </Container>
        </div>
    )
}

export default Description
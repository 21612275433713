import React from 'react'
import { useTranslation } from 'react-i18next'
// Material-UI
import Typography   from '@material-ui/core/Typography'
import Divider      from '@material-ui/core/Divider'
import TextField    from '@material-ui/core/TextField'
import MenuItem     from '@material-ui/core/MenuItem'
// Estilos
import makeStyles   from '@material-ui/styles/makeStyles'
// Funciones
import { moneyFormat } from '../../functions'
// Iconos
import PersonIcon   from '@material-ui/icons/Person'
import HotelIcon    from '@material-ui/icons/Hotel'
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    imagen: {
        flex: 1,
        padding: "8px 16px",
        minWidth: 300
    },
    img: {
        width: '100%'
    },
    descripcion: {
        flex: 1,
        padding: "8px 16px",
        minWidth: 300
    },
    precio: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px 16px"
    },
    icons: {
        display: 'flex',
        alignItems: 'center'
    }
}))

function list(count) {
    const max = count > 4? 4:count
    let list = []
    for (let i = 0; i <= max; i++) {
       list.push(<MenuItem key={i} value={i}>{i}</MenuItem>) 
    }
    return list;
}

function RoomCard({ cuarto, indice, onChange, openGallery }) {

    const classes = useStyles()
    const [cantidad, setCantidad] = React.useState(0)
    const { t, i18n } = useTranslation() 

    return (
        <>
            <Divider />
            <div className={classes.container}>
                <div className={classes.imagen}>
                    <img alt="" src={cuarto.image_url} className={classes.img} />
                </div>
                <div className={classes.descripcion}>
                    <Typography variant="h6" color="secondary">
                        {cuarto.nombre_hab[i18n.language]}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {cuarto.descripcion_hab[i18n.language]}
                    </Typography>
                    <div style={{height: 16}} />
                    <div className={classes.icons}>
                        <PersonIcon style={{marginRight: 4}} color="secondary" />
                        <Typography style={{marginRight: 16}} variant="h6" component="span" className={classes.feature} color="secondary">
                            {cuarto.huespedes} 
                        </Typography>
                        <HotelIcon style={{marginRight: 4}} color="secondary" />
                        <Typography style={{marginRight: 16}} variant="h6" component="span" className={classes.feature} color="secondary">
                            {cuarto.camas}
                        </Typography>
                    </div>
                    <Button color="primary" onClick={() => openGallery(cuarto.imagenes)}>
                        {t('Cuarto.Galeria')}
                    </Button>
                </div>
                <div className={classes.precio}>
                    <Typography variant="h6" color="secondary" gutterBottom>
                        {t('Cuarto.Desde')}
                    </Typography>
                    <Typography variant="h4" color="secondary">
                        {moneyFormat(cuarto.tarifa.precio)} <Typography component="span" variant="caption">MXN</Typography>
                    </Typography>
                    <Typography variant="h6" color="secondary">
                        {t('Cuarto.Noche')}
                    </Typography>
                    <div style={{height: 16}} />
                    <Typography variant="subtitle1">
                        {t('Cuarto.Habitaciones')}
                    </Typography>
                    <TextField
                        select
                        value={cantidad}
                        onChange={({target}) => {
                            setCantidad(target.value)
                            onChange(target.value, cuarto)
                        }}
                        variant="outlined"
                    >
                        {list(cuarto.cuartos_count)}
                    </TextField>
                </div>
            </div>
        </>
    )
}

export default RoomCard
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Gallery from 'react-grid-gallery'

const useStyles = makeStyles(theme => ({
    spacing: {
        height: 16
    },
    gallery: {
        padding: 8,
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto",
    }
}))

function Hotel() {

    const classes = useStyles()
    const HOTEL = [
        {
            src: require("../../images/gallery/hblue_hotel01.bd401b55.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel01.bd401b55.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hotel02.2168887f.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel02.2168887f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 212,
        },
        {
            src: require("../../images/gallery/hblue_hotel03.e8b26495.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel03.e8b26495.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hotel04.e78ace37.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel04.e78ace37.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel05.3bec0a0b.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel05.3bec0a0b.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel06.4d12a8a5.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel06.4d12a8a5.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hotel07.ec1ae717.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel07.ec1ae717.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hotel08.62ce95d2.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel08.62ce95d2.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
        {
            src: require("../../images/gallery/hblue_hotel09.1c1aed63.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel09.1c1aed63.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel10.33bce214.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel10.33bce214.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel11.7fd921fe.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel11.7fd921fe.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel13.55aeed63.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel13.55aeed63.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel14.0454b71c.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel14.0454b71c.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel16.96436e5f.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel16.96436e5f.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel17.36b89af9.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel17.36b89af9.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel18.87307224.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel18.87307224.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel19.08b000a7.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel19.08b000a7.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel20.f0f1971a.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel20.f0f1971a.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel21.454d7111.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel21.454d7111.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel25.c6d3fbdd.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel25.c6d3fbdd.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel26.40891fe0.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel26.40891fe0.jpg"),
            thumbnailWidth: 320,
            thumbnailHeight: 240,
        },
        {
            src: require("../../images/gallery/hblue_hotel27.ae047111.jpg"),
            thumbnail: require("../../images/gallery/hblue_hotel27.ae047111.jpg"),
            thumbnailWidth: 240,
            thumbnailHeight: 320,
        },
    ]

    return (
        <div className={classes.gallery}>
            <Gallery 
                images={HOTEL}
                enableImageSelection={false}
                onClickImage={()=>{}}
                backdropClosesModal={true}
                rowHeight={320}
            />
        </div>
    )
}

export default Hotel
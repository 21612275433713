import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Card, CardMedia, CardContent } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32
    },
    card: {
        height: '100%'
    },
    media:{
        height: 0,
        paddingTop: '56.25%'
    },
    spacing: {
        height: 32
    }
}))

function Cards() {

    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="center">
                <Grid item  xs={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={require('../../images/visitlapaz0113e5d76c.jpg')}
                        />
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('LaPaz.Card1Tile')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card1Text1')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card1Text2')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card1Text3')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={require('../../images/visitlapaz0221e06bc2.jpg')}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('LaPaz.Card2Tile')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card2Text1')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card2Text2')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div className={classes.spacing} />
            <Grid container spacing={2} justify="center">
                <Grid item  xs={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={require('../../images/visitlapaz03a5a81dd6.jpg')}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('LaPaz.Card3Title')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card3Text1')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card3Text2')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card3Text3')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card3Text4')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={require('../../images/visitlapa04a5467bdf.jpg')}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('LaPaz.Card4Title')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card4Text1')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card4Text2')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card4Text3')}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="justify" gutterBottom>
                                {t('LaPaz.Card4Text4')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Cards
import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#cccccc",
        height: 500,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: 'flex',
        alignItems: "flex-end",
        padding: 16,
        [theme.breakpoints.down('sm')]: {
            height: 300
        }
    },
    titulo: {
        color: "#fff"
    }
}))

function Banner({title, image}) {

    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div 
            className={classes.root}
            style={{
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('+ image + ')',
            }}
        >
            <Typography variant={matches?"h6":"h2"} className={classes.titulo} component="h3">
                {title}
            </Typography>
        </div>
    )
}

export default Banner
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useTheme, makeStyles } from '@material-ui/core/styles'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const content = [
    require('../images/hblue_banner1.JPG'),
    require('../images/hblue_banner2.jpg'),
    require('../images/hblue_banner3.jpg'),
    require('../images/hblue_banner4.jpg'),
    require('../images/hblue_banner5.jpg')
]

const useStyles = makeStyles(theme => ({
    step: {
        width: '100%',
        height: 400,
        [theme.breakpoints.up('sm')]: {
            height: 600,
        },
        [theme.breakpoints.up('lg')]: {
            height: 700,
        }
    }
}))

function Welcome() {

    const classes = useStyles()
    const theme = useTheme()
    const [step, setStep] = React.useState(0)

    return (
        <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={step}
            onChangeIndex={(step) => setStep(step)}
            enableMouseEvents
        >
            {content.map((imagen, index) => (
                <div 
                    key={index} 
                    style={{
                        background: 'url('+imagen+')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    className={classes.step}
                />
                
            ))}
        </AutoPlaySwipeableViews>
    )
}

export default Welcome
import React from 'react'
import clsx from 'clsx'
import { AppBar, Toolbar, IconButton, Divider, List, ListItem, Drawer, ListItemText, Button, ListItemIcon } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HomeIcon from '@material-ui/icons/Home'
import HotelIcon from '@material-ui/icons/Hotel'
import PhotoIcon from '@material-ui/icons/Photo'
import SearchIcon from '@material-ui/icons/Search'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    spacing: {
        flexGrow: 1
    },
    button: {
        color: "#fff",
    },
    img: {
        marginRight: 4
    },
    bookNow: {
        margin: 16
    }
}));

function MobileHeader() {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false)

    const { t, i18n } = useTranslation()

    return (
        <React.Fragment>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => setOpen(true)}
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/" className={classes.link}>
                        <img alt="logo"  src={require('../images/logob.png')} width="60"/>
                    </Link>
                    <div className={classes.spacing} />
                    <Button onClick={() => {
                        const lng =  i18n.language === 'en' ? 'es':'en'
                        i18n.changeLanguage(lng)
                        localStorage.setItem('lng', lng)
                    }} className={classes.button}>
                        <img alt="lng" src={i18n.language === 'en'?
                            require('../images/mexico.png'):require('../images/usa.png')} className={classes.img} /> 
                            {i18n.language === 'en'?'ES':'EN'}
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => setOpen(false)}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/">
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Home')} />
                    </ListItem>
                    <ListItem button component={Link} to="/rooms">
                        <ListItemIcon>
                            <HotelIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Rooms')} />
                    </ListItem>
                    <ListItem button component={Link} to="/gallery">
                        <ListItemIcon>
                            <PhotoIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Galery')} />
                    </ListItem>
                    <ListItem button component={Link} to="/search">
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Booking')} />
                    </ListItem>
                    <ListItem button component={Link} to="/contact">
                        <ListItemText primary={t('Menu.ContactUs')} />
                    </ListItem>
                </List>
                <Divider />
                <Button variant="contained" color="primary" className={classes.bookNow} component={Link} to={'/new'}>
                    {t('Menu.BookNow')}
                </Button>
            </Drawer>
        </React.Fragment>
    )
}

export default MobileHeader
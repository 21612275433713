import React            from 'react'
import Container        from '@material-ui/core/Container'
import Paper            from '@material-ui/core/Paper'
import Grid             from '@material-ui/core/Grid'
import Typography       from '@material-ui/core/Typography'
import TextField        from '@material-ui/core/TextField'
import InputAdornment   from '@material-ui/core/InputAdornment'
import IconButton       from '@material-ui/core/IconButton'
import List             from '@material-ui/core/List'
import ListItem         from '@material-ui/core/ListItem'
import ListItemText     from '@material-ui/core/ListItemText'
import ListItemAvatar   from '@material-ui/core/ListItemAvatar'
import ListSubheader    from '@material-ui/core/ListSubheader'
import Avatar           from '@material-ui/core/Avatar'
import Card             from '@material-ui/core/Card'
import CardContent      from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles       from '@material-ui/styles/makeStyles'
import SearchIcon       from '@material-ui/icons/Search'
import ErrorIcon        from '@material-ui/icons/Error'
import EmailIcon        from '@material-ui/icons/Email'
import PhoneIcon        from '@material-ui/icons/Phone'
import BusinessIcon     from '@material-ui/icons/Business'
import HomeIcon         from '@material-ui/icons/Home'
import PlaceIcon        from '@material-ui/icons/Place'
import CloseIcon        from '@material-ui/icons/Close'
import HotelIcon        from '@material-ui/icons/Hotel'
import PersonIcon       from '@material-ui/icons/Person'
import PeopleICon       from '@material-ui/icons/People'
import CheckCircleIcon  from '@material-ui/icons/CheckCircle'
import Chip             from '@material-ui/core/Chip'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Axios    from 'axios'
import moment   from 'moment'
import { useTheme }         from '@material-ui/core/styles';
import useMediaQuery        from '@material-ui/core/useMediaQuery'
import { useTranslation }   from 'react-i18next'
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '64px 0px',
    },
    paper: {
        padding: 16
    },
    error: {
        borderColor: theme.palette.error.main,
        borderStyle: "solid",
        borderWidth: 1,
        padding: "8px 32px",
        display: 'flex',
        alignItems: 'center',
        marginTop: 32
    },
    icono: {
        backgroundColor: "#212121"
    },
    head: {
        display: 'flex',
    },
    loading: {
        margin: '16px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    loadingContent:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    var1: {
        backgroundColor: "#29b6f6",
        color: "#fff"
    },
    var2: {
        backgroundColor: "#43a047",
        color: "#fff"
    }
}))

function moneyFormat(value) {
    return '$ ' + Number(value.toString()).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function total(reservados) {
    let t = 0
    reservados.forEach(item => {
        t += parseFloat(item.precio)
    })
    return moneyFormat(t)
}

function Content() {

    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const { i18n, t } = useTranslation()

    const [search, setSearch] = React.useState("")
    const [res, setRes] = React.useState(null)
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [found, setFound] = React.useState(false)

    const handleSearch = () => {
        if (search !== "") {
            setLoading(true)
            setFound(false)
            Axios.get("https://hotelws.adftec.com/api/consultar/" + search)
            .then(response => {
                if(response.data) {
                    setRes(response.data)
                } else {
                    setFound(true)
                }
                setLoading(false)
            })
            .catch(error => {
                setError(true)
                setLoading(false)
            })
        }
    }

    const Render = () => {
        return (
            <Paper className={classes.paper}>
                <div className={classes.head}>
                    <Typography variant="h6">  
                        {t('Consultar.Titulo2')}
                    </Typography>
                    <div style={{flexGrow: 1}} />
                    <IconButton onClick={() => setRes(null)}>
                        <CloseIcon color="action" />
                    </IconButton>
                </div>
                <Typography variant="h6">
                    {t('Consultar.Estado')}: <Chip label={res.estado_reservacion.nombre} className={classes["var"+res.estado]} />
                </Typography>
                <Grid container>
                    <Grid item sm={12} md={6}>
                        <List className={classes.list}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>#</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Codigo')} secondary={res.code} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Nombre')} secondary={res.huesped.nombre_completo} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <EmailIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Correo')} secondary={res.huesped.email} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <PhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Tel')} secondary={res.huesped.telefono} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: "#00c853"}}>
                                        <CheckCircleIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Entrada')} secondary={moment(res.desde).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: "#e65100"}}>
                                        <CheckCircleOutlineIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Salida')} secondary={moment(res.hasta).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")} />
                            </ListItem>
                        </List>
                        <List>
                            <ListSubheader>
                                {t('Consultar.Cuartos')}
                            </ListSubheader>
                            {res.cuartos_reservados.map((reservado, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icono}>
                                            <HotelIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={reservado.cuarto.tipo_cuarto.nombre_hab[i18n.language]} secondary={<><PeopleICon /> {reservado.huespedes}</>} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <List>
                            <ListSubheader>
                                {t('Consultar.Facturacion')}
                            </ListSubheader>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <BusinessIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Compania')} secondary={res.huesped.compania || "-"} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>#</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Rfc')} secondary={res.huesped.rfc || "-"} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <HomeIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Dir')} secondary={(res.huesped.direccion || "-") + " " + (res.huesped.direccion2 || "")} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>#</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Postal')} secondary={res.huesped.codigo_postal || "-"} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <PlaceIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Ciudad')} secondary={res.huesped.ciudad || "-"} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.icono}>
                                        <PlaceIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Consultar.Pais')} secondary={(res.huesped.estado? res.huesped.estado + ", " : "-") + (res.huesped.pais || "") } />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <div style={{height: 16}} />
                <Typography variant={matches?"h6":"h4"}>
                    Total: {total(res.cuartos_reservados)}
                </Typography>
            </Paper>
        )
    }

    return (
        <Container className={classes.root} fixed>
            {res ? 
            <Render />
            :
            (loading?
            <Card className={classes.loading}>
                <CardContent className={classes.loadingContent}>
                    <CircularProgress size={64} style={{marginBottom: 4}} />
                    <Typography variant="h6">
                        {t('Action.Loading')}...
                    </Typography>
                </CardContent>
            </Card>
            :<>
                <Typography variant={matches?"h6":"h4"} align="center" gutterBottom>
                        {t('Consultar.Titulo')}
                </Typography>
                <Typography variant={matches?"subtitle1":"h6"} align="center">
                    {t('Consultar.Subtitle')}
                </Typography>
                <div style={{display: "flex", justifyContent: "center", paddingTop: 32}}>
                    <TextField
                        variant="outlined"
                        value={search}
                        onChange={({target}) => setSearch(target.value)}
                        onKeyDown={(e) => {
                            if(e.which === 13) {
                                handleSearch()
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                maxLength: 128,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => handleSearch()}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        placeholder={t('Consultar.PlaceHolder')}
                        style={{backgroundColor: "#fff"}}
                    />
                </div>
                {error && 
                <div className={classes.error}>
                    <ErrorIcon style={{fontSize: 48, marginRight: 8}} color="error" />
                    <div>
                        <Typography variant="h6" color="error">
                            Ha ocurrido un error, intenta de nuevo.
                        </Typography>
                    </div>
                </div>}
                {found &&
                <Typography variant="h6" align="center" color="error">
                    No se ha entonctrado ningun resultado.
                </Typography>}
            </>)}
        </Container>
    )
}

export default Content
import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom' 

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff"
    },
    img: {
        width: '100%'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16
    }
}))

function Section2() {
    
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item md={6} xs={12}>
                    <img src={require('../../images/106658635.jpg')} alt="" className={classes.img} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.content}>
                    <Typography variant={matches?"h6":"h4"}align="center" gutterBottom>
                        {t('Section2.Title1')}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        {t('Section2.Text1_1')}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        {t('Section2.Text1_2')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.root} wrap="wrap-reverse">
                <Grid item md={6} xs={12} className={classes.content}>
                    <Typography variant={matches?"h6":"h4"}align="center" gutterBottom>
                        {t('Section2.Title2')}
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        {t('Section2.Text2_1')}
                    </Typography>
                    <Button variant="contained" size="large" color="primary" component={Link} to="/lapaz">
                        {t('Section2.Button2')}
                    </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={require('../../images/13112018-IMG_7716.JPG')} alt="" className={classes.img} />
                </Grid>
            </Grid>
            <Grid container className={classes.root}>
                <Grid item md={6} xs={12}>
                    <img src={require('../../images/132362380.jpg')} alt="" className={classes.img} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.content}>
                    <Typography variant={matches?"h6":"h4"}align="center" gutterBottom>
                        {t('Section2.Title3')}
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        {t('Section2.Text3_1')}
                    </Typography>
                    <Button variant="contained" size="large" color="primary" component={Link} to="/rooms">
                        {t('Section2.Button3')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Section2
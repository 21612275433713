import React from 'react'

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

import Header from '../../components/Header'
import MobileHeader from '../../components/MobileHeader'
import Banner from '../../components/Banner'
import Footer from '../../components/Footer'
import Content from './Content'

function Contacto() {

    window.scrollTo(0,0)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()
    
    return (
        <React.Fragment>
            <h1 hidden>Hotel Hblue</h1>
            {matches? <MobileHeader />:<Header />}
            <main>
                <Banner title={t('Contacto.Title')} image={require('../../images/87482973222f12b7.jpg')} />
                <Content />
                <Footer />
            </main>
        </React.Fragment>
    )
}

export default Contacto
import React from 'react'
import { 
    Grid,
    Typography, 
    Container, 
    Paper, 
    TextField, 
    Button, 
    List, 
    ListItem, 
    ListItemAvatar,
    ListItemText, 
    CircularProgress,
    Snackbar,
    IconButton
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Call'
import LocationIcon from '@material-ui/icons/LocationOn'
import CloseIcon from '@material-ui/icons/Close'

import Axios from 'axios'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '64px 0px'
    },
    infoContent: {
        padding: "32px 16px"
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16
    },
    paper: {
        padding: "32px 16px"
    },
    input: {
        paddingTop: 8,
        paddingBottom: 8
    },
    button: {
        marginTop: 8
    },
    head: {
        backgroundColor: "#212121",
        padding: "32px 16px"
    },
    close: {
        padding: theme.spacing(0.5),
    }
}))

function Content() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles()

    const [nombre, setNombre] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [mensaje, setMensaje] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [snackbar, setSnackbar] = React.useState(false)
    const [feedback, setFeedback] = React.useState("")
    const { t } = useTranslation()

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        Axios.post("https://hotelws.adftec.com/api/send_message", {
            email: email,
            nombre: nombre,
            mensaje: mensaje
        })
        .then(response => {
            setLoading(false)
            setEmail("")
            setMensaje("")
            setNombre("")
            setSnackbar(true)
            setFeedback("Mensaje enviado.")
        })
        .catch(error => {
            setLoading(false)
            setSnackbar(true)
            setFeedback("Ocurrio un error, intente de nuevo.")
        })
    }

    return (
        <Container fixed className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" component="h6" color="primary">
                            {t('Contacto.FormTitle')}
                        </Typography>
                        <Typography variant="subtitle1" component="p">
                            {t('Contacto.FormSubtitle')}
                        </Typography>
                        <div style={{height: 16}} />
                        <form onSubmit={handleSubmit}>
                            <div className={classes.input}>
                                <TextField
                                    fullWidth
                                    label= {t('Contacto.InputNombre')}
                                    variant="outlined"
                                    value={nombre}
                                    onChange={({target}) => setNombre(target.value)}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 64
                                    }}
                                    required
                                    id="input-Nombre"
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label= {t('Contacto.InputCorreo')}
                                    variant="outlined"
                                    value={email}
                                    onChange={({target}) => setEmail(target.value)}
                                    inputProps={{
                                        type: "email",
                                        maxLength: 254
                                    }}
                                    required
                                    id="input-email"
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField
                                    fullWidth
                                    label= {t('Contacto.InputMensaje')}
                                    variant="outlined"
                                    value={mensaje}
                                    onChange={({target}) => setMensaje(target.value)}
                                    rows={3}
                                    rowsMax={5}
                                    multiline
                                    inputProps={{
                                        type: "text",
                                        maxLength: 128
                                    }}
                                    required
                                    id="input-direccion"
                                />
                            </div>
                            <Button color="primary" variant="contained" fullWidth className={classes.button} type="submit" disabled={loading}>
                                {loading ? <CircularProgress size={22} color="inherit" /> : "Enviar"}
                            </Button>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <Paper className={classes.head}>
                        <Typography variant={matches?"h6":"h5"} component="h6" style={{color:"#fff"}}>
                            {t('Contacto.HeadTitle')}
                        </Typography>
                        <Typography variant={matches?"body1":"subtitle1"} component="p" style={{color:"#fff"}}>
                            {t('Contacto.HeadSubtitle')}
                        </Typography>
                    </Paper>
                    <Paper className={classes.infoContent}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <PhoneIcon color="primary" fontSize="large" />
                                </ListItemAvatar>
                                <ListItemText primary={t('Contacto.InfoPhone')} secondary="612 123 2583" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <EmailIcon color="primary" fontSize="large" />
                                </ListItemAvatar>
                                <ListItemText primary={t('Contacto.InfoEmail')} secondary="info@hotelbluelapaz.com" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <LocationIcon color="primary" fontSize="large" />
                                </ListItemAvatar>
                                <ListItemText primary={t('Contacto.InfoAddress')} secondary="Aquiles Serdán #2042 entre Ignacio Allende y Antonio Rosales Col. Centro, La Paz, Baja California Sur, México. C.P. 23000" />
                            </ListItem>
                        </List>
                    </Paper> 
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={snackbar}
                autoHideDuration={6000}
                onClose={() => setSnackbar(false)}
                ContentProps={{
                'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{feedback}</span>}
                action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    className={classes.close}
                    onClick={() => setSnackbar(false)}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
        </Container>
    )
}

export default Content
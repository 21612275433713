import React, { Suspense } from 'react'
import 'typeface-roboto'
import CssBaseline from '@material-ui/core/CssBaseline'
import CircularProgress from '@material-ui/core/CircularProgress'
import Router from './config/Router'
import {  createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import moment from 'moment'
import { Fab } from '@material-ui/core'
import NavigationIcon from '@material-ui/icons/Navigation'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#1976d2',
            main: '#1565c0',
            dark: '#0d47a1',
            contrastText: '#fff',
        },
        secondary:{
            light: '#54140a',
            main: '#a76042',
            dark: '#fdc0a5',
            contrastText: '#fff',
        },
    },
    typography: {
        useNextVariants: true,
    },
})

const Loader = () => (
    <div style={{
        display: 'flex', 
        height: '100%', 
        alignItems: 'center', 
        justifyContent: 'center' 
    }}>
        <CircularProgress size={64} />
    </div>
)

function Page() {

    const { i18n } = useTranslation()
    moment.locale(i18n.language)

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Router />
                <Fab
                    style={{
                        position: "fixed",
                        right: theme.spacing(2),
                        bottom: theme.spacing(2),
                        backgroundColor: "#000000a4",
                        color: "#fff"
                    }}
                    onClick={() => window.scrollTo(0,0)}
                >
                    <NavigationIcon />
                </Fab>
            </ThemeProvider>
        </React.Fragment>
    )
}

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
    )
}

export default App

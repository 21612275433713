import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const lng = localStorage.getItem('lng')?localStorage.getItem('lng'):"es"

i18n
.use(Backend)
.use(initReactI18next)
.init({
  lng: lng,
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  }
})
if(!localStorage.getItem('lng')) {
  i18n.use(LanguageDetector)
  localStorage.setItem('lng', LanguageDetector)
}

export default i18n;
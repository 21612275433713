import React from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DateRangeIcon from '@material-ui/icons/DateRange'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ('moment/locale/es')


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 200,
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            top: 128,
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            top: 72,
            width: '100%'
        }
    },
    paper: {
        padding: 64,
        marginLeft: 32,
        backgroundColor: "#000000A5",
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            height: 'auto',
            marginLeft: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 32,
            display: 'block',
            height: 'auto',
            marginLeft: 0,
        }
    },
    dialog: {
        backgroundColor: "#000"
    },
    input: {
        marginRight: 16,
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 8,
    },
    button: {
        marginTop: 16
    },
    center: {
        textAlign: 'center'
    }
}))

function ReservationForm() {

    let now = new Date()
    now.setHours(0,0,0,0)

    const classes = useStyles()
    const [desde, setDesde] = React.useState(moment(now).add(2, 'd'))
    const [opend, setOpend] = React.useState(false)
    const [hasta, setHasta] = React.useState(moment(desde).add(1, 'd'))
    const [openh, setOpenh] = React.useState(false)
    const { t, i18n } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Grid container className={classes.root} justify={matches?"center":"flex-start"}>
            <Grid item>
                <Paper className={classes.paper}>
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={i18n.language}>
                        <Typography variant="h6" style={{color:"#fff"}}>
                            <b>{t('ReservationForm.From')}</b>
                        </Typography>
                        <DatePicker
                            margin="dense"
                            inputVariant="outlined"
                            value={desde}
                            onChange={(date) => {
                                setDesde(date)
                                if(date.isSameOrAfter(hasta)) {
                                    setHasta(moment(date).add(1, 'd'))
                                }
                                setOpend(false)
                            }}
                            variant="inline"
                            minDate={moment()}
                            format={i18n.language === "es" ? "DD/MMM/YYYY" : "MMM/DD/YYYY"}
                            InputProps={{
                                endAdornment: <DateRangeIcon />,
                                inputProps: {style: {
                                    textTransform: 'capitalize'
                                }},
                                onClick:() => setOpend(true),
                                style: {padding: "4px 8px"}
                            }}
                            required
                            open={opend}
                            className={classes.input}
                        />
                        <Typography variant="h6" style={{color:"#fff"}}>
                            <b>{t('ReservationForm.To')}</b>
                        </Typography>
                        <DatePicker
                            margin="dense"
                            inputVariant="outlined"
                            value={hasta}
                            onChange={(date) =>  {
                                setHasta(date)
                                setOpenh(false)
                            }}
                            variant="inline"
                            minDate={moment(desde).add(1, 'd')}
                            format={i18n.language === "es" ? "DD/MMM/YYYY" : "MMM/DD/YYYY"}
                            InputProps={{
                                endAdornment: <DateRangeIcon />,
                                inputProps: {style: {
                                    textTransform: 'capitalize'
                                }},
                                onClick:() => setOpenh(true),
                                style: {padding: "4px 8px"}
                            }}
                            required
                            className={classes.input}
                            open={openh}
                        />
                        <div className={classes.center}>
                            <Button variant="contained" color="primary" type="submit" className={classes.button} name="submit" component={Link} to={"/new?from="+desde.format("YYYY-MM-DD")+"&to="+hasta.format("YYYY-MM-DD")}>
                                {t('ReservationForm.Sumbmit')}
                            </Button>
                        </div>
                    </MuiPickersUtilsProvider>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ReservationForm
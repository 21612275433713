import React from 'react'
import { Container, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import HoursDialog from './HoursDialog'
import TourDialog from './TourDialog'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 64,
        paddingBottom: 64
    },
    container: {
        marginTop: 16
    },
    media:{
        height: 0,
        paddingTop: '56.25%'
    },
    action:{
        justifyContent: 'flex-end'
    },
    card: {
        height: '100%'
    }
}))

function Section1() {

    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const [hours, setHours] = React.useState(false)
    const [tours, setTours] = React.useState(false)

    return (
        <div className={classes.root}>
            <Container fixed>
                <Typography variant={matches?"h6":"h3"} component="h2" align="center" gutterBottom>
                    {t('Section1.Welcome')}
                </Typography>
                <Typography variant="body1" align="center">
                    {t('Section1.Description')}
                </Typography>
            </Container>
            <Container fixed className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.media}
                                image={require('../../images/roomsmain.jpg')}
                                title="Paella dish"
                            />
                            <CardContent>
                                <Typography variant="h6">
                                    {t('Section1.Pool')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t('Section1.PoolText')}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.action}>
                                <Button color="primary" component={Link} to="/gallery">
                                    {t('Section1.PoolAction')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.media}
                                image={require('../../images/restaurante.jpg')}
                                title="Paella dish"
                            />
                            <CardContent>
                                <Typography variant="h6">
                                    {t('Section1.Restaurant')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t('Section1.RestaurantText')}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.action}>
                                <Button color="primary" onClick={() => setHours(true)}>
                                    {t('Section1.RestaurantAction')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.media}
                                image={require('../../images/28433771629_ea320821a5_b.jpg')}
                                title="Paella dish"
                            />
                            <CardContent>
                                <Typography variant="h6">
                                    {t('Section1.Tours')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t('Section1.ToursText')}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.action}>
                                <Button color="primary" onClick={() => setTours(true)}>
                                    {t('Section1.ToursAction')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <HoursDialog 
                open={hours}
                handleClose={() => setHours(false)}
            />
            <TourDialog 
                open={tours}
                handleClose={() => setTours(false)}
            />
        </div>
    )
}

export default Section1
import React from 'react'
import { Button, Card, Typography, CardContent, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { useTranslation } from 'react-i18next'
// Material Picker
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    input: {
        margin: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    date: {
        marginRight: 16,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: 'inherit',
            marginTop: 8
        }
    },
    button: {
        marginBottom: 2,
        [theme.breakpoints.down('sm')]: {
            marginTop: 16,
            marginBottom: 0,
        }
    }
}))

function Paso1({ next, from, to }) {

    let form = React.createRef()
    const classes = useStyles()
    const { t, i18n } = useTranslation()

    let now = new Date()
    now.setHours(0,0,0,0)

    const [desde, setDesde] = React.useState(from ?  moment(from) : moment(now).add(2, 'd'))
    const [opend, setOpend] = React.useState(false)
    const [hasta, setHasta] = React.useState(to ? moment(to) : moment(desde).add(1, 'd'))
    const [openh, setOpenh] = React.useState(false)
 
    const handleSubmit = (e) => {
        e.preventDefault()
        next(desde, hasta)
    }

    return (
        <Container fixed>
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant={"h6"} gutterBottom>
                        {t('Nueva.Step1')}
                    </Typography>
                    <div style={{height: 32}} />
                    <form onSubmit={handleSubmit} ref={ref => form = ref}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={i18n.language}>
                            <div className={classes.container}>
                                <div className={classes.date}>
                                    <Typography variant="subtitle1" component="p">
                                        {t('Nueva.From')}
                                    </Typography>
                                    <DatePicker
                                        margin="dense"
                                        inputVariant="outlined"
                                        value={desde}
                                        onChange={(date) => {
                                            setDesde(date)
                                            if(date.isSameOrAfter(hasta)) {
                                                setHasta(moment(date).add(1, 'd'))
                                            }
                                            setOpend(false)
                                        }}
                                        variant="inline"
                                        minDate={moment()}
                                        format={i18n.language === "es" ? "DD/MMM/YYYY" : "MMM/DD/YYYY"}
                                        InputProps={{
                                            endAdornment: <DateRangeIcon />,
                                            inputProps: {style: {
                                                textTransform: 'capitalize'
                                            }},
                                            onClick:() => setOpend(true),
                                        }}
                                        required
                                        open={opend}
                                        className={classes.input}
                                    />
                                </div>
                                <div className={classes.date}>
                                    <Typography variant="subtitle1" component="p">
                                        {t('Nueva.To')}
                                    </Typography>
                                    <DatePicker
                                        margin="dense"
                                        inputVariant="outlined"
                                        value={hasta}
                                        onChange={(date) =>  {
                                            setHasta(date)
                                            setOpenh(false)
                                        }}
                                        variant="inline"
                                        minDate={moment(desde).add(1, 'd')}
                                        format={i18n.language === "es" ? "DD/MMM/YYYY" : "MMM/DD/YYYY"}
                                        InputProps={{
                                            endAdornment: <DateRangeIcon />,
                                            inputProps: {style: {
                                                textTransform: 'capitalize'
                                            }},
                                            onClick:() => setOpenh(true)
                                        }}
                                        required
                                        className={classes.input}
                                        open={openh}
                                    />
                                </div>
                                <Button 
                                    variant="contained"
                                    color="primary" 
                                    onClick={() => form.dispatchEvent(new Event("submit"))}
                                    name="next"
                                    className={classes.button}
                                >
                                    {t('Action.Next')}
                                </Button>
                            </div>
                        </MuiPickersUtilsProvider>
                    </form>
                </CardContent>
            </Card>
            <div style={{height: 16}} />
        </Container>
    )
}

export default Paso1
import React from 'react'

import Dialog           from '@material-ui/core/Dialog'
import DialogTitle      from '@material-ui/core/DialogTitle'
import DialogContent    from '@material-ui/core/DialogContent'
import DialogActions    from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button           from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Success({ res }) {

    const { t } = useTranslation()

    return(
        <Dialog
            open={true}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>{t('Success.Titulo')}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {t('Success.Text1')} <b>{res}</b>
                </DialogContentText>
                <DialogContentText>
                    {t('Success.Text2')}
                </DialogContentText>
                <DialogContentText align="center">
                    {t('Success.Text3')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" component={Link} to="/">
                    {t('Success.Button')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
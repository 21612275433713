import React from 'react'

import { Container, Typography, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function Description() {

    const { t } = useTranslation()

    return (
        <Container fixed>
            <Paper style={{padding: 32, margin: '16px 8px'}}>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p1')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p2')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p3')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p4')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p5')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{fontSize: '1.25rem'}}>
                    {t('Privacidad.p6')}
                </Typography>
            </Paper>
        </Container>
    )
}

export default Description
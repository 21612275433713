import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next' 

const useStyles = makeStyles(theme => ({
    root: {
        margin: '16px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    content:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}))

export default function Loading() {

    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <CircularProgress size={64} style={{marginBottom: 4}} />
                <Typography variant="h6">
                    {t('Action.Loading')}...
                </Typography>
            </CardContent>
        </Card>
    )
}
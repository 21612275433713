import React from 'react'
import { AppBar, Toolbar, Container, Typography, Button, Divider, Link } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { useTranslation } from 'react-i18next'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    appbar: {
        backgroundColor: "#00000065",
        color: "#e0e0e0"
    },
    container: {
        paddingTop: 8,
        paddingBottom: 8
    },
    section: {
        display: 'flex',
        alignItems: 'center'
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
    },
    spacing: {
        flexGrow: 1
    },
    social: {
        width: 24,
        height: 24,
        marginRight: 8
    },
    divider: {
        backgroundColor: "#e0e0e0",
        marginTop: 8,
        marginBottom: 8
    },
    icon: {
        marginLeft: 8,
        marginRight: 8
    },
    button: {
        color: "#fff",
    },
    img: {
        marginRight: 4
    },
    menu: {
        display: 'flex',
        justifyContent: 'flex-end'
    }, 
    link: {
        textDecoration: "none",
        padding: "4px 8px",
        color: "#FFF",
        '&:hover': {
            color: "#bdbdbd"
        }
    },
    linkSelected: {
        color: "#29b6f6"
    },
    booknow: {
        marginLeft: 8,
    }
}))

function ElevationScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}
function Header(props) {

    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ElevationScroll {...props}>
            <AppBar position={"fixed"} className={classes.appbar}>
                <Toolbar>
                    <Container fixed className={classes.container} >
                        <div className={classes.section}>
                            <Button onClick={() => {
                                const lng =  i18n.language === 'en' ? 'es':'en'
                                i18n.changeLanguage(lng)
                                localStorage.setItem('lng', lng)
                            }} className={classes.button}>
                                <img alt="lng" src={i18n.language === 'en'?
                                    require('../images/mexico.png'):require('../images/usa.png')} className={classes.img} /> 
                                    {i18n.language === 'en'?'ES':'EN'}
                            </Button>
                            <div className={classes.left}>
                                <PhoneIcon fontSize="inherit" className={classes.icon} />
                                <Typography variant={matches?"caption":"body1"}>
                                    612 123 2583
                                </Typography>
                                <EmailIcon fontSize="inherit" className={classes.icon} />
                                <Typography variant={matches?"caption":"body1"}>
                                    Info@hotelbluelapaz.com
                                </Typography>
                            </div>
                            <div className={classes.spacing} />
                            <div className={classes.right}>
                                <Link href={"https://www.facebook.com/HotelHBlue"} target="_blank">
                                    <img alt="facebook" src={require('../images/facebook.png')} className={classes.social} />
                                </Link>
                                <Link href={"https://twitter.com/HotelBlue_BC"} target="_blank">
                                    <img alt="twitter" src={require('../images/twitter.png')} className={classes.social} />
                                </Link>
                                <Link href={"https://www.instagram.com/explore/locations/1008113182609330/hotel-hblue/"} target="_blank">
                                    <img alt="instagram" src={require('../images/instagram.png')} className={classes.social} />
                                </Link>
                            </div>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.section}>
                            <RouterLink to="/" className={classes.link}>
                                <img alt="logo" src={require('../images/logob.png')} width={100} />
                            </RouterLink>
                            <div className={classes.spacing} />
                            <div className={classes.menu}>
                                <RouterLink to="/" className={classes.link}>
                                    <Typography variant="h6" color="inherit">
                                        {t('Menu.Home')}
                                    </Typography>
                                </RouterLink>
                                <RouterLink to="/rooms" className={classes.link}>
                                    <Typography variant="h6" color="inherit">
                                        {t('Menu.Rooms')}
                                    </Typography>
                                </RouterLink>
                                <RouterLink to="/gallery" className={classes.link}>
                                    <Typography variant="h6" color="inherit">
                                        {t('Menu.Galery')}
                                    </Typography>
                                </RouterLink>
                                <RouterLink to="/search" className={classes.link}>
                                    <Typography variant="h6" color="inherit">
                                        {t('Menu.Booking')}
                                    </Typography>
                                </RouterLink>
                                <RouterLink to="/contact" className={classes.link}>
                                    <Typography variant="h6" color="inherit">
                                        {t('Menu.ContactUs')}
                                    </Typography>
                                </RouterLink>
                                <Button variant="contained" color="primary" className={classes.booknow} component={RouterLink} to={'/new'}>
                                    {t('Menu.BookNow')}
                                </Button>
                            </div>
                        </div>
                    </Container>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}

export default Header
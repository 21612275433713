import React        from 'react'
import Container    from '@material-ui/core/Container'
import Paper        from '@material-ui/core/Paper'
import makeStyles   from '@material-ui/styles/makeStyles' 
import Typography   from '@material-ui/core/Typography'
import moment       from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox     from '@material-ui/core/Checkbox'
import Button       from '@material-ui/core/Button'
import List         from '@material-ui/core/List'
import ListItem     from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import LensIcon     from '@material-ui/icons/Lens'
import ErrorIcon    from '@material-ui/icons/Error'
import { useTranslation } from 'react-i18next'

import Politicas from './Politicas'
import Privacidad from './Privacidad'
import Terminos from './Terminos'
import Success from './Success'

import Axios from 'axios'

const useStyles = makeStyles(theme => ({
    root: {
        padding: "32px 32px 24px 32px"
    },
    heaeder: {
        display: 'flex',
        justifyContent: 'center'
    },
    imagen: {
        width: 200
    },
    subtitulo: {
        color: "#bdbdbd",
        borderBottom: "1px solid #bdbdbd"
    },
    subtitulo2: {
        color: "#bdbdbd"
    },
    row: {
        display: "flex",
        marginTop: 8,
        marginBottom: 24
    },
    cell: {
        flex: 1
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tarifaHead: {
        backgroundColor: "#eeeeee"
    },
    tarfiaFooter: {
        borderTop: "1px solid #eeeeee",
        fontWeight: 700,
        fontSize: "1.5em"
    },
    tabledata: {
        padding: 8,
        textAlign: "left"
    },
    link: {
        padding: 0,
        fontSize: 'inherit',
        textTransform: 'none'
    },
    error: {
        borderColor: theme.palette.error.main,
        borderStyle: "solid",
        borderWidth: 1,
        padding: "8px 32px",
        display: 'flex',
        alignItems: 'center'
    }
}))

function huespedes(cuartos) {
    let h = 0
    cuartos.forEach(item => {
        h += item.huespedes
    })
    return h
}

function moneyFormat(value) {
    return '$ ' + Number(value.toString()).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function total(cuartos, dias) {
    let t = 0
    cuartos.forEach(item => {
        t += parseFloat(item.cuarto.tarifa.precio * dias)
    })
    return moneyFormat(t)
}

function tipoTarjeta(tipo) {
    switch (tipo) {
        case "visa":
            return "Visa"
        case "mastercard":
            return "Mastercard"
        case "amex":
            return "American Express"
        case "discover":
            return "Discover"
        case "dinersclub":
            return "Diners Club"
        default:
            return "Error"
    }
}

export default function Final({ reservacion, onBack }) {

    const classes = useStyles()
    const dias = moment(reservacion.hasta).diff(moment(reservacion.desde),"d")
    const [checked, setChecked] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [serror, setSerror] = React.useState(false)
    const [politicas, setPoliticas] = React.useState(false)
    const [privacidad, setPrivacidad] = React.useState(false)
    const [terminos, setTerminos] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [res, setRes] = React.useState(null)
    const { t, i18n } = useTranslation()

    const submit = () => {
        if(!checked) {
            setError(true)
        } else {
            setLoading(true)
            setSerror(false)
            const request = {
                hotel_id: 2,
                desde: reservacion.desde,
                hasta: reservacion.hasta,
                comentarios: "",
                cuartos_reservados: reservacion.cuartos_reservados,
                huesped: reservacion.huesped,
                tarjeta: reservacion.tarjeta,
                lng: i18n.language
            }
            Axios.post("https://hotelws.adftec.com/api/web_res", request)
            .then(response => {
                setLoading(false)
                setRes(response.data)
            })
            .catch(error => {
                setLoading(false)
                setSerror(true)
            })
        }
    }

    return (
        <Container fixed>
            <div style={{textAlign: "right"}}>
                <Button variant="text" onClick={() => onBack()}>
                    {t('Final.Regresar')}
                </Button>
            </div>
            <div className={classes.heaeder}>
                <img src={require('../../images/logo.png')} alt="" className={classes.imagen} />
            </div>
            <div style={{height: 32}} />
            <Typography variant="h4" align="center" component="h1" gutterBottom>
                {t('Final.Title')}
            </Typography>
            <div style={{height: 8}} />
            <Paper className={classes.root}>
                <Typography variant="h6" component="p" align="center">
                    {t('Final.Subtitle')}
                </Typography>
                <div style={{height: 16}} />
                <Typography variant="subtitle1" component="p" className={classes.subtitulo}>
                    {t('Final.Informacion')}
                </Typography>
                <div className={classes.row}>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Desde')}</b>
                        </Typography>
                        <Typography variant="body1" style={{textTransform: "capitalize"}}>
                            {moment(reservacion.desde).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")}
                        </Typography>
                    </div>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Hasta')}</b>
                        </Typography>
                        <Typography variant="body1" style={{textTransform: "capitalize"}}>
                            {moment(reservacion.desde).format(i18n.language === "es" ? "dddd, D MMMM YYYY" : "dddd, MMMM Do YYYY")}
                        </Typography>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Nombre')}</b>
                        </Typography>
                        <Typography variant="body1">
                            {reservacion.huesped.nombre + " " + reservacion.huesped.apellido}
                        </Typography>
                    </div>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Email')}</b>
                        </Typography>
                        <Typography variant="body1">
                            {reservacion.huesped.email}
                        </Typography>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Nhabitaciones')}</b>
                        </Typography>
                        <Typography variant="body1">
                            {reservacion.cuartos_reservados.length}
                        </Typography>
                    </div>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Huespedes')}</b>
                        </Typography>
                        <Typography variant="body1">
                            {huespedes(reservacion.cuartos_reservados)}
                        </Typography>
                    </div>
                </div>
                <div style={{height: 16}} />
                <Typography variant="subtitle1" component="p" className={classes.subtitulo2}>
                    {t('Final.Habtitulo')}
                </Typography>
                <div className={classes.tableWrapper}>
                    <table style={{width: "100%", borderCollapse: "collapse"}}>
                        <thead>
                            <tr className={classes.tarifaHead}>
                                <th className={classes.tabledata}>{t('Final.Habitacion')}</th>
                                <th className={classes.tabledata}>{t('Final.Huespedes')}</th>
                                <th className={classes.tabledata} style={{textAlign: "right"}}>{t('Final.Precio')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservacion.cuartos_reservados.map((item, index) => (
                                <tr key={index}>
                                    <td className={classes.tabledata}>
                                        {item.cuarto.nombre_hab[i18n.language]}
                                    </td>
                                    <td className={classes.tabledata}>
                                        {item.huespedes}
                                    </td>
                                    <td className={classes.tabledata} style={{textAlign: "right"}}>
                                        {moneyFormat(item.cuarto.tarifa.precio * dias)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className={classes.tarfiaFooter}>
                                <td className={classes.tabledata} style={{textAlign: "right"}} colSpan={2}>
                                    TOTAL
                                </td>
                                <td className={classes.tabledata} style={{textAlign: "right"}}>
                                    {total(reservacion.cuartos_reservados, dias)}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <Typography variant="subtitle1" component="p" className={classes.subtitulo}>
                    {t('Final.Metodo')}
                </Typography>
                <Typography variant="body1" style={{marginTop: 16}}>
                    <b>{tipoTarjeta(reservacion.tarjeta.tipo) + t('Final.Terminacion') + reservacion.tarjeta.numero.slice(-4)}</b>
                </Typography>
                <div style={{height: 32}} />
                <Typography variant="subtitle1" component="p" className={classes.subtitulo}>
                    {t('Final.Adicional')}
                </Typography>
                <div className={classes.row}>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Entrada')}</b>
                        </Typography>
                        <Typography variant="body1">
                            15:00 HRS
                        </Typography>
                    </div>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Salida')}</b>
                        </Typography>
                        <Typography variant="body1">
                            12:00HRS
                        </Typography>
                    </div>
                    <div className={classes.cell}>
                        <Typography variant="body1">
                            <b>{t('Final.Ayuda')}</b>
                        </Typography>
                        <Typography variant="body1">
                            {t('Final.Telefono')}
                        </Typography>
                    </div>
                </div>
                <div style={{height: 32}} />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <FormControlLabel
                        control={<Checkbox 
                                checked={checked}
                                onChange={({target}) => {
                                    setChecked(target.checked)
                                    setError(false)
                                }}
                        />}
                        label={<>
                            {t('Final.Terminos1')}
                            <Button variant="text" color="primary" className={classes.link} onClick={() => setTerminos(true)}>
                                {t('Final.Terminos2')}
                            </Button>
                            <Button variant="text" color="primary" className={classes.link} onClick={() => setPrivacidad(true)}>
                                {t('Final.Terminos3')}
                            </Button>
                            {t('Final.Terminos4')}
                            <Button variant="text" color="primary" className={classes.link} onClick={() => setPoliticas(true)}>
                                {t('Final.Terminos5')}
                            </Button>
                            {t('Final.Terminos6')}
                        </>}
                    />
                    {error && 
                    <div className={classes.error}>
                        <ErrorIcon style={{fontSize: 64}} color="primary" />
                        <div>
                            <Typography variant="subtitle1" color="primary">
                                {t('Final.Confirm')}
                            </Typography>
                        </div>
                    </div>}
                    {serror &&
                    <div className={classes.error}>
                        <ErrorIcon style={{fontSize: 64}} color="primary" />
                        <div>
                            <Typography variant="subtitle1" color="primary">
                                {t('Final.ErrorTitle')}
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {t('Final.ErrorText')}
                            </Typography>
                        </div>
                    </div>}
                    <Button variant="contained" color="primary" size="large" style={{marginTop: 16}} onClick={() => submit()} disabled={loading}>
                        {loading ? <CircularProgress size={22} color="inherit" /> : t('Final.BtnReservar')}
                    </Button>
                </div>
            </Paper>
            <div style={{height: 32}} />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography variant="h6" component="p" align="center">
                    {t('Final.Cancelacion')}
                </Typography>
                <List style={{minWidth: 200, maxWidth: 500}}>
                    <ListItem>
                        <ListItemIcon><LensIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('Final.Cancelacion1')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LensIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('Final.Cancelacion2')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LensIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('Final.Cancelacion3')} />
                    </ListItem>
                </List>
            </div>
            <Politicas open={politicas} handleClose={() => setPoliticas(false)} />
            <Privacidad open={privacidad} handleClose={() => setPrivacidad(false)} />
            <Terminos open={terminos} handleClose={() => setTerminos(false)} />
            {res && <Success res={res} />}
        </Container>
    )
}
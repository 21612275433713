import React from 'react'

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Slider from '../../components/Slider'
import Header from '../../components/Header'
import MobileHeader from '../../components/MobileHeader'
import ReservationForm from './ReservationForm'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Information from '../../components/Information'
import Map from '../../components/Map'
import Footer from '../../components/Footer'

function Welcome() {
    
    window.scrollTo(0,0)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <h1 hidden>Hotel Hblue</h1>
            {matches? <MobileHeader />:<Header />}
            <main>
                <Slider />
                <ReservationForm />
                <Section1 />
                <Section2 />
                <Section3 />
                <Map />
                <Information />
                <Footer />
            </main>
        </React.Fragment>
    )
}

export default Welcome
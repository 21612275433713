import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Container, AppBar, Tabs, Tab, Paper } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import Hotel from './Hotel'
import Habitaciones from './Habitaciones'
import Amenidades from './Amenidades'
import Alberca from './Alberca'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 32,
        paddingBottom: 32
    }
}))

function Menu() {

    const theme = useTheme()
    const classes = useStyles()
    const { t } = useTranslation()
    const [index, setIndex] = React.useState(0)
    const [h, setH] = React.useState(false)

    return (
        <Container fixed className={classes.root}>
            <Paper>
                <AppBar position="static">
                    <Tabs
                    value={index} 
                    onChange={(e, index) => {
                        setIndex(index)
                        setH(true)
                    }}
                    variant="scrollable"
                    scrollButtons="auto">
                        <Tab label={t('Galeria.Tab1')} />
                        <Tab label={t('Galeria.Tab2')} />
                        <Tab label={t('Galeria.Tab3')} />
                        <Tab label={t('Galeria.Tab4')} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={(index) => {
                        setIndex(index)
                        setH(true)
                    }}
                    animateHeight={h}
                >
                    <Hotel />
                    <Habitaciones />
                    <Amenidades />
                    <Alberca />
                </SwipeableViews>
                
            </Paper>
        </Container>
    )
}

export default Menu
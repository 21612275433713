import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationIcon from '@material-ui/icons/LocationOn'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 32,
        paddingBottom: 32
    },
    container: {
        flex: 1,
        minWidth: 200,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: 32
        }
    },
    icon: {
        fontSize: 64
    }
}))
function Information() {

    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div style={{backgroundColor: '#fff'}}>
            <Container fixed className={classes.root}>
                <div className={classes.container}>
                    <EmailIcon fontSize="inherit" color="primary" className={classes.icon} />
                    <Typography variant="h5">
                        {t('Information.Email')}
                    </Typography>
                    <Typography variant="subtitle1" >
                        info@hotelbluelapaz.com
                    </Typography>
                </div>
                <div className={classes.container}>
                    <PhoneIcon fontSize="inherit" color="primary" className={classes.icon} />
                    <Typography variant="h5">
                        {t('Information.Phone')}
                    </Typography>
                    <Typography variant="subtitle1" >
                        612 123 2583
                    </Typography>
                </div>
                <div className={classes.container}>
                    <LocationIcon fontSize="inherit" color="primary" className={classes.icon} />
                    <Typography variant="h5">
                        {t('Information.Address')}
                    </Typography>
                    <Typography variant="subtitle1" >
                        Aquiles Serdán #2042 entre Ignacio Allende y Antonio Rosales Col. Centro, La Paz, Baja California Sur, México. C.P. 23000
                    </Typography>
                </div>
            </Container>
        </div>
    )
}

export default Information
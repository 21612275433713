import React from 'react'
import { Card, CardActions, CardContent, Button, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PhoneTextField from '../../components/PhoneTextField'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '8px 16px',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    input: {
        padding: 8,
    },
    title: {
        padding: "16px 16px 0px 16px"
    }
}))

function Paso3({ next, back, huesped }) {

    let submit = React.useRef()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()
    const { t } = useTranslation()
    
    const [nombre, setNombre]               = React.useState(huesped ? (huesped.nombre         || ""): "")
    const [apellido, setApellido]           = React.useState(huesped ? (huesped.apellido       || ""): "")
    const [email, setEmail]                 = React.useState(huesped ? (huesped.email          || ""): "")
    const [telefono, setTelefono]           = React.useState(huesped ? (huesped.telefono       || ""): "")
    const [compania, setCompania]           = React.useState(huesped ? (huesped.compania       || ""): "")
    const [rfc, setRfc]                     = React.useState(huesped ? (huesped.rfc            || ""): "")
    const [direccion, setDireccion]         = React.useState(huesped ? (huesped.direccion      || ""): "")
    const [direccion2, setDireccion2]       = React.useState(huesped ? (huesped.direccion2     || ""): "")
    const [codigo_postal, setCodigoPostal]  = React.useState(huesped ? (huesped.codigo_postal  || ""): "")
    const [ciudad, setCiudad]               = React.useState(huesped ? (huesped.ciudad         || ""): "")
    const [estado, setEstado]               = React.useState(huesped ? (huesped.estado         || ""): "")
    const [pais, setPais]                   = React.useState(huesped ? (huesped.pais           || ""): "")

    const handleSubmit = (e) => {
        e.preventDefault()
        const huesped = {
            nombre: nombre,
            apellido: apellido,
            email: email,
            telefono: telefono,
            compania: compania,
            direccion: direccion,
            direccion2: direccion2,
            codigo_postal: codigo_postal,
            ciudad: ciudad,
            estado: estado,
            pais: pais,
            rfc: rfc
        }
        next(huesped)
    }

    return (
        <div>
            <Card className={classes.root}>
                <Typography variant={matches?"h6":"h4"} className={classes.title} align="center">
                    {t('Nueva.Step3')}
                </Typography>
                <CardContent className={classes.content}>
                    <form onSubmit={handleSubmit}>
                        <Grid container>  
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    autoFocus
                                    fullWidth
                                    label={t('Nueva.InputName')}
                                    variant="outlined"
                                    value={nombre}
                                    onChange={({target}) => setNombre(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 64
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputLastName')}
                                    variant="outlined"
                                    value={apellido}
                                    onChange={({target}) => setApellido(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 64
                                    }}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container>    
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputEmail')}
                                    variant="outlined"
                                    value={email}
                                    onChange={({target}) => setEmail(target.value)}
                                    inputProps={{
                                        type: "email",
                                        maxLength: 254
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputPhone')}
                                    variant="outlined"
                                    value={telefono}
                                    onChange={({target}) => setTelefono(target.value) }
                                    InputProps={{
                                        type: "text",
                                        inputComponent: PhoneTextField,
                                        inputProps: {
                                            maxLength: 16
                                        }
                                    }}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="h6" gutterBottom>
                            {t('Nueva.GuestSubtitle')}
                        </Typography>
                        <Grid container>    
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputFacturar')}
                                    variant="outlined"
                                    value={compania}
                                    onChange={({target}) => setCompania(target.value)}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 128
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputRfc')}
                                    variant="outlined"
                                    value={rfc}
                                    onChange={({target}) => setRfc(target.value) }
                                    inputProps={{
                                        type: "text",
                                        maxLength: 24
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.input}>
                            <TextField
                                fullWidth
                                label={t('Nueva.InputAddress')}
                                variant="outlined"
                                value={direccion}
                                onChange={({target}) => setDireccion(target.value.toUpperCase())}
                                rows={2}
                                rowsMax={4}
                                multiline
                                inputProps={{
                                    type: "text",
                                    maxLength: 128
                                }}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextField
                                fullWidth
                                label={t('Nueva.InputAddress2')}
                                variant="outlined"
                                value={direccion2}
                                onChange={({target}) => setDireccion2(target.value.toUpperCase())}
                                rows={2}
                                rowsMax={4}
                                multiline
                                inputProps={{
                                    type: "text",
                                    maxLength: 128
                                }}
                            />
                        </div>
                        <Grid container>    
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputZip')}
                                    variant="outlined"
                                    value={codigo_postal}
                                    onChange={({target}) => setCodigoPostal(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 16
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputCity')}
                                    variant="outlined"
                                    value={ciudad}
                                    onChange={({target}) => setCiudad(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 189
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>    
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField 
                                    fullWidth
                                    label={t('Nueva.InputState')}
                                    variant="outlined"
                                    value={estado}
                                    onChange={({target}) => setEstado(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 90
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.input}>
                                <TextField
                                    fullWidth
                                    label={t('Nueva.InputCountry')}
                                    variant="outlined"
                                    value={pais}
                                    onChange={({target}) => setPais(target.value.toUpperCase())}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 90
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <button hidden type="submit" name="submit" ref={submit} />
                    </form>
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end', padding: "16px 8px"}}>
                    <Button onClick={back} name="atras" variant="contained">
                        {t('Action.Back')}
                    </Button>
                    <Button color="primary" onClick={() =>  submit.current.click()} name="siguiente" variant="contained">
                        {t('Action.Next')}
                    </Button>
                </CardActions>
            </Card>
            <div style={{height: 16}} />
        </div>
    )
}

export default Paso3